const initState = {
  sidebar: false
};

const localReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SIDEBAR_MINIMIZE':
      return {
        ...state,
        sidebar: action.data
      };
    default:
      return state;
  }
};

export default localReducer;
