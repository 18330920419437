import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var fbConfig = {
  apiKey: "AIzaSyD6qcK4afk1UGUyIiB85uUuos9dtrH45o0",
  authDomain: "cafecohort.firebaseapp.com",
  databaseURL: "https://cafecohort.firebaseio.com",
  projectId: "cafecohort",
  storageBucket: "cafecohort.appspot.com",
  messagingSenderId: "1073246418321",
  appId: "1:1073246418321:web:2730a62c704f6258ec1630",
  measurementId: "G-18L698RW0L"
};

firebase.initializeApp(fbConfig);
firebase.firestore().settings({ 
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  // timestampsInSnapshots: true  
});
firebase.firestore().enablePersistence()
export default firebase;
