import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect, NavLink, Link } from 'react-router-dom';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import '../../assets/css/bootstrap.min.css';
import Navbar from '../layout/Navbar';
import Sidebar from '../layout/Sidebar';

class ItemList extends Component {
  state = {
    searchText: '',
    itemsFrom: '0',
  };

  searchTextChange = (event) => {
    this.setState({ searchText: event.target.value });
  };
  handleChange = (event) => {
    this.setState({ itemsFrom: event.target.value });
  };
  
  render() {
    const { inventoryItems, auth } = this.props;
    //   console.log(this.props);
    if (!auth.uid) return <Redirect to="/signin" />;

    if (inventoryItems) {
      return (
        <div>
          <Navbar />
          <Sidebar />

          <div className="main-panel">
            <div className="content">
              <div className="page-inner">
                <div className="page-header">
                  <h4 className="page-title">Inventory Items</h4>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">

                      {/* Begin mobile header */}
                      <div className="card-header d-md-down-none d-block d-sm-none">
                        <div className="d-flex justify-content-start flex-column">
                          <div>
                            <NavLink to="/createItem">
                              <button className="blue-btn">Add New Item</button>
                            </NavLink>
                          </div>
                        </div>
                      </div>

                      {/* Begin desktop header */}
                      <div className="card-header mb-3 d-none d-sm-block">
                        <div className="d-flex justify-content-between flex-wrap">
                          
                          <div> {/* Search box */}
                            <div className="d-flex">
                              {this.state.itemsFrom === '0' && (
                                <div className="search-box">
                                  <div className="d-flex">
                                    <input className="form-control height-inherit" placeholder="Search..." value={this.state.searchText} 
                                          onChange={this.searchTextChange.bind(this)}/>
                                  </div>
                              </div>
                              )}
                            </div>
                          </div>

                          <NavLink to="/createItem">
                            <button className="blue-btn">Add New Item</button>
                          </NavLink>
                        </div>
                      </div>

                      {/* Begin mobile view */}
                      <div className="card-body pb-0 d-md-down-none d-block d-sm-none">
                        <ul className="mobile-view ">

                          {/* Search box */}
                          {this.state.itemsFrom === '0' && (
                            <div className="search-box">
                              <div className="d-flex">
                                <input className="form-control height-inherit" 
                                  placeholder="Search..." 
                                  value={this.state.searchText} 
                                  onChange={this.searchTextChange.bind(this)}/>
                              </div>
                            </div>
                          )}

                          {inventoryItems && inventoryItems.map((item,key) => {
                            let found = true;
                            if(this.state.itemsFrom === '0' && this.state.searchText !== ''){
                              found = item.name.toLowerCase().includes(this.state.searchText.toLowerCase());
                            }
                            if(found)
                              return (
                                <li key={key}>
                                  <Link to={"/item/" + item.id} key={item.id}>
                                    <p>{item.name}</p>
                                  </Link>
                                  <p className="d-flex justify-content-between align-items-center">
                                    <span>par: {item.par}</span>
                                    <span className="ml-5">
                                      {item.category}
                                    </span>
                                  </p>
                                </li>
                              );
                          })}

                        </ul>
                      </div>
                      {/* Begin Desktop view */}
                      <div className="card-body pb-0 d-none d-sm-block">
                        <table className="table shopping-history">
                          <thead>
                            <tr>
                              <th>Item name</th>
                              <th style={{ width: '180px' }}>Par</th>
                              <th style={{ width: '140px' }}>Category</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inventoryItems &&
                              inventoryItems.map((item) => {
                                let found = true;
                                if(this.state.itemsFrom === '0' && this.state.searchText !== ''){
                                  found = item.name.toLowerCase().includes(this.state.searchText.toLowerCase());
                                }
                                if(found)
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <Link to={"/item/" + item.id} key={item.id}>
                                          {item.name}
                                        </Link>
                                      </td>
                                      <td>{item.par}</td>
                                      <td>{item.category}</td>
                                    </tr>
                                  );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          {/* Begin Add Item modal*/}
          <div className="modal fade" id="addItem" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Add New Item</h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row" style={{ padding: '0px 5px' }}>
                    <div className="form-group col-md-12">
                      <label>Item name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="List name sample"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Par amount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Assign to category</label>
                      <select className="form-control">
                        <option>Select category from a list</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </div>
                    <div className="form-group col-md-12">
                      <label htmfor="createProDesc">Item notes</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        style={{ resize: 'none' }}
                        placeholder="Some text"></textarea>
                    </div>
                    <a href="#" className="add-upc-pro">
                      Add/UPC Product
                    </a>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="close-btn"
                    data-dismiss="modal">
                    Cancel
                  </button>
                  <button type="button" className="blue-btn">
                    Add Item
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End Add Item modal */}
        </div>
      );
    } else {
      return (
        <div className="container center">
          <p>Loading cafes...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  //  console.log('state: ', state)
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    inventoryItems: state.firestore.ordered.inventoryItems,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }
//    console.log('defaultCafe: ', props.profile.defaultCafeId);

    return [
      {
        collection: 'cafes',
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: 'inventoryItems', orderBy: ["dateCreated", "desc"] }],
        storeAs: 'inventoryItems',
      },
    ];
  }),
)(ItemList);
