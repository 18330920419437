export const getStatusColorClass = (status = "ok") => {
    switch (status) {
      case 'ok':
        return "light-green-btn";
      case 'low':
        return "light-yellow-btn";
      case 'out':
        return "pink-btn"
      default:
        return "pink-btn";
    }
  }
  
  export const getNextStatus = (status = "ok") => {
    switch (status) {
      case 'ok':
        return "low";
      case 'low':
        return "out";
      case 'out':
        return "ok"
      default:
        return "ok";
    }
  }

  export const  getAllProductMinimum = (item, prices) =>{
    // return this.getMinPriceOfProduct(item.productIds[0])
    if(item && item.productIds && item.productIds.length > 0){
      var index = {};
      var lowest = Number.POSITIVE_INFINITY
      for (let i = 0; i < item.productIds.length; i++) {
        const value = getMinPriceOfProduct(item.productIds[i], prices)
        if(value.price < lowest){
          index = value
          lowest = value.price
         }
      }
      return index.price
    }
    if(item && item.itemId){
      var index = getMinPriceOfProduct(item.itemId, prices, 1)
      return index.price
    }
    else{
      return item.price;
    }
  }

  export const getMinPriceOfProduct = (productId, prices, flag) =>{
    var index ={};
    if(prices){
      var lowest = Number.POSITIVE_INFINITY
      const p = Object.entries(prices).filter(([id, value]) => flag ? value.itemId === productId : value.productId === productId);
      for (let i = 0; i < p.length; i++) {
        const element = p[i][1];
        if(element.price < lowest){
         index = element
         lowest = element.price
        }
      }
    }
    return index
  }
