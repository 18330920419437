import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { addItemToList } from "../../store/actions/shoppingActions";
import { getAllProductMinimum } from "../utils/HelperFunctions";

class AddItemDialog extends React.Component {
  state = {
    set: false,
    searchText: '',
    itemsFrom: '0',
  };

  componentDidUpdate() {
    if (!this.state.set && this.props.items) {
      const items = Object.entries(this.props.items).map(([id, value]) => ({
        ...value,
        id,
      }));
      this.setState({ items, set: true });
    }
  }

  addItemToList(itm) {
    var item = itm
    if(item && item.productIds && item.productIds[0]) {
      item.price = getAllProductMinimum(item, this.props.prices)
      item.price = item.price ? item.price : null
    }
    item.itemId = item.id;
    delete item.id;
    const shopping = JSON.parse(JSON.stringify(this.props.shopping));
    this.props.addItemToList(
      shopping?.list?.length > 0 ? [...shopping.list, item] : [item]
    );
  }

  searchTextChange = (event) => {
    this.setState({ searchText: event.target.value });
  };
  handleChange = (event) => {
    this.setState({ itemsFrom: event.target.value });
  };

  render() {
    const { items, category } = this.state;
    const { categories } = this.props;
    return (
      <div id="addNewItemShoppingmodal" className="modal fade" role="dialog">
     				<div className="modal-dialog add-new-item-modal">
            <div className="modal-content">
              <div className="modal-header">
							<h4 className="modal-title">Add new item</h4>
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						  </div>
              <div className="modal-body">
             
              <div className="d-md-down-none d-block d-sm-none">
								<ul className="new-item-filter">
									<li onClick={()=> this.setState({ itemsFrom: '0' })}
                   className={this.state.itemsFrom === '0' ? "active" : ""}>Search</li>
									<li onClick={()=> this.setState({ itemsFrom: '1' })}
                   className={this.state.itemsFrom === '1' ? "active" : ""}>Category</li>
									<li onClick={()=> this.setState({ itemsFrom: '2' })}
                   className={this.state.itemsFrom === '2' ? "active" : ""}>Display All</li>
								</ul>
                {this.state.itemsFrom === '1' && (
                  <select
                      className="form-control"
                      onChange={({ target }) =>
                              this.setState({
                                category: target.value,
                                show: false,
                              })
                            }>
                            {categories?.map((name, i) => {
                              return (
                                <option key={i} value={name}>
                                  {!name
                                    ? 'All'
                                    : name.charAt(0).toUpperCase() +
                                      name.slice(1)}
                                </option>
                              );
                            })}
                          </select>
                )}
                {this.state.itemsFrom === '0' && (
                  <div className="search-box">
                    <div className="d-flex">
                        <input className="form-control height-inherit" placeholder="Type name of an item" value={this.state.searchText} 
                              onChange={this.searchTextChange.bind(this)}/>
                    </div>
                </div>
                )}
							</div>

              <div className="d-none d-sm-block">
                    <div className="d-flex mb-4 two-select-group mt-2 pb-1 flex-wrap ">
                        <div>
                            <div className="name font-weight-bold">Add items from</div>
                            <div className="d-flex">
                                <select className="form-control category-select mr-2" onChange={this.handleChange.bind(this)}>
                                    <option value="0">Search</option>
                                    <option value="1">Category</option>
                                    <option value="2">List of all items</option>
                                </select>
                                {this.state.itemsFrom === '0' && (
                                  <div className="search-box">
                                    <div className="d-flex">
                                        <input className="form-control height-inherit" placeholder="Type name of an item" value={this.state.searchText} 
                                             onChange={this.searchTextChange.bind(this)}/>
                                        {/* <button className="blue-btn" style={{width: 200, marginLeft: 10 }}>Add item</button> */}
                                    </div>
                                </div>
                                )}
                            </div>
                            </div>
                       {this.state.itemsFrom === '1' && (
                        <div className="category-opts" >
                            <div className="name font-weight-bold">Choose category</div>
                            <select
                      className="form-control"
                      onChange={({ target }) =>
                              this.setState({
                                category: target.value,
                                show: false,
                              })
                            }>
                            {categories?.map((name, i) => {
                              return (
                                <option key={i} value={name}>
                                  {!name
                                    ? 'All'
                                    : name.charAt(0).toUpperCase() +
                                      name.slice(1)}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                       )}
                        <div className="category-opts">
                           
                        </div>
                   </div>
               </div>
               <ul className="mobile-view d-md-down-none d-block d-sm-none">
               {items
                ?.filter((item) =>
                           this.state.itemsFrom !== '1' ? true : category ? item?.category === category : true,
                        )
                        ?.map((item, i) => {
                  const exists =
                    this.props?.shopping?.list?.length > 0
                      ? this.props?.shopping?.list?.some(
                          (elem) => elem.id === item.id
                        )
                      : false;
                    let found = true;
                    if(this.state.itemsFrom === '0' && this.state.searchText !== ''){
                       found = item.name.toLowerCase().includes(this.state.searchText.toLowerCase());
                    }
                  if (!exists && found)
								return (
                  <li key={i}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="item d-flex" style={{flexDirection: 'column', width: '100%'}}>
                        <p className="title">{item.name}</p>
                        <p className="content d-flex justify-content-between">
                          <span>
                            <input type="text" className="short-input" defaultValue={item.par} readOnly={true}/>
                          </span>
                          <span>
                            <button 
                             onClick={() => {
                               this.addItemToList(item, i);
                             }}
                              className="blue-btn" 
                              style={{minWidth: 60}}>
                              Add
                            </button>
                          </span>
                        </p>
                    </div>
                </div>
              </li>);
               })};
                </ul>
          <table className="table d-none d-sm-block">
            {/* <table className="responsive-tabe highlight centered itemtable"> */}
              <thead>
                <tr>
                  <th>Item name</th>
                  <th>Par quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {items
                ?.filter((item) =>
                           this.state.itemsFrom !== '1' ? true : category ? item?.category === category : true,
                        )?.map((item, i) => {
                  const exists =
                    this.props?.shopping?.list?.length > 0
                      ? this.props?.shopping?.list?.some(
                          (elem) => elem.id === item.id
                        )
                      : false;
                    let found = true;
                    if(this.state.itemsFrom === '0' && this.state.searchText !== ''){
                       found = item.name.toLowerCase().includes(this.state.searchText.toLowerCase());
                    }
                  if (!exists && found)
                    return (
                      <tr key={i}>
                        <td>{item.name}</td>
                        <td>
                           <input type="text" className="short-input" value={item.par} readOnly={true}/>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              this.addItemToList(item, i);
                            }}
                            style={{ cursor: "pointer" }}
                            className="blue-btn"
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    );
                })}
              </tbody>
            </table>
        </div>
        <div className="modal-footer">
          {/* <a className="modal-close waves-effect waves-green btn-flat">Close</a> */}
        </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToList: (list) => dispatch(addItemToList(list)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    items: state.firestore.data.items,
    products: state.firestore.data.products,
    prices: state.firestore.data.prices,
    categories: state.item.categories,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }
    return [
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "inventoryItems" }],
        storeAs: "items",
      },
      {
        collection: "products",
        storeAs: "products",
      },
      {
        collection: "prices",
        storeAs: "prices",
      },
    ];
  })
)(AddItemDialog);
