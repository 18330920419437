import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { promote } from "../../store/actions/itemActions";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import CreateItemList from "./CreateItemList"
import ReactTooltip from 'react-tooltip'
var PAGE_SIZE = 10;
class InventoryLists extends Component {
  state = {
    path: null,
    currentPage: 0
  };

  promote(inventory) {
    if (
      window.confirm(
        "Do you want to promote this inventory list to the shopping list?"
      )
    )
      this.props.promote(inventory);
  }

  onHisoryClick = (key) =>{
    const index= 1
     this.props.history.push(`/inventory/${key}/${index}/conducted`)
  }

  getAllPagesIndex(){
    var list = []
    var j = 1;
    for (let i = 0; i < this.props.inventories.length; i+=PAGE_SIZE) {
      list.push(j) 
      j++
    }
    console.log(this.props.inventories);
    return list
  }

  onPageNumberClick(number){

  }
  render() {
    const { inventoryLists, auth, inventories } = this.props;
    const { path } = this.state;
    if (path) return <Redirect to={path} />;
    if (!auth.uid) return <Redirect to="/signin" />;
    if (inventoryLists) {
      return (
        <div>
          <Navbar />
          <Sidebar />

          <div className="main-panel">
            <div className="content mb-4">
              <div className="page-inner">
                <div className="page-header">
                  <h4 className="page-title">Inventory list</h4>
                  <div className="btn-group btn-group-page-header ml-auto inventory-btn-group">
                    <button
                      type="button"
                      className="blue-btn d-none d-sm-block"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-toggle="modal"
                      data-target="#addNewInventoryModal">
                      Create Inventory list
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">Inventory Lists</div>
                      </div>

                      {/* Begin Mobile View */}
                      <div className="card-body pb-0">
                        <ul className="mobile-view d-md-down-none d-block d-sm-none">
                          {inventoryLists?.map((list) => {
                            return (
                              <li key={list.id}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="item w-100">
                                    <p className="title mb-1">
                                      {list.name}
                                    </p>
                                    <p className="content d-flex justify-content-between">
                                      {list.description}
                                    </p>
                                    <div className="d-flex mt-3">
                                      <a onClick={() => this.setState({
                                        path: `/inventory/${list.id}/conduct`,
                                        })}
                                        className="d-flex mr-4">
                                        <div className="property-icon"></div>
                                        Conduct
                                      </a>
                                      <a onClick={() => this.setState({
                                        path: `/inventory/${list.id}`,
                                        })}
                                      className="d-flex">
                                        <div className="edit-pen-icon"></div>
                                        Edit
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      {/* Begin Desktop View */}
                      <div className="card-body pb-0 d-none d-sm-block">
                        <table className="table inventory-log-talbe">
                          <thead>
                            <tr>
                              <th style={{width: '40%'}}>Inventory name</th>
                              <th style={{width: '50%'}}>Description</th>
                              <th style={{width: '10%'}}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {inventoryLists?.map((list) => {
                              return (
                                <tr key={list.id}>
                                  <td>
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          path: `/inventory/${list.id}/conduct`,
                                        })
                                      }
                                      key={list.id}
                                      className="waves-effect waves-light btn-small">
                                      {list.name}
                                    </a>
                                  </td>
                                  <td><span className="text-one-line">{list.description}</span></td>
                                  <td>
                                    <div className="d-flex">
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          path: `/inventory/${list.id}/conduct`,
                                        })
                                      }
                                      data-tip='' data-for='conduct'
                                      className="waves-effect waves-light btn-small">
                                      <div className="property-icon"></div>
                                    </a>
                                    <ReactTooltip offset={{top: 0, left: 15}} id='conduct' effect="solid">
                                    Conduct Inventory
                                    </ReactTooltip>
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          path: `/inventory/${list.id}`,
                                        })
                                      }
                                      data-tip='' data-for='edit'
                                      className="waves-effect waves-light btn-small">
                                      <div className="edit-pen-icon"></div>
                                    </a>
                                    <ReactTooltip offset={{top: 0, left: 15}} id='edit' effect="solid">
                                    Edit
                                    </ReactTooltip>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* Inventory History Card */}
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">Inventory history</div>
                      </div>

                      {/* Begin Mobile List */}
                      <div className="card-body pb-0 d-md-down-none d-block d-sm-none">
                        <ul className="mobile-view">
                          {inventories?.slice(this.state.currentPage * PAGE_SIZE, (this.state.currentPage+1) * PAGE_SIZE).map((inventory) => {
                            return (
                              <li key={inventory.id}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="item w-100">
                                      <p className="title">
                                      <a onClick={() => this.onHisoryClick.call(this, inventory.id)}>
                                  {inventory.name}
                                  </a>
                                      </p>
                                      <p className="content d-flex">
                                        Date conducted
                                      </p>
                                      <p
                                        className="content d-flex"
                                        style={{ color: '#6c757d' }}>
                                        <span>
                                          {moment(
                                            inventory.dateCreated.toDate(),
                                          ).calendar()}
                                        </span>
                                      </p>
                                    </div>
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"></i>
                                  </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      {/* Begin Desktop view */}
                      <div className="card-body pb-0 d-none d-sm-block">
                        <table className="table conduct-list-table">
                          <thead>
                            <tr>
                              <th>List name</th>
                              <th>Date conducted</th>
                              <th style={{ textAlign: 'center' }}>Promote</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inventories?.slice(this.state.currentPage * PAGE_SIZE, (this.state.currentPage+1) * PAGE_SIZE).map((inventory) => {
                              return (
                                <tr key={inventory.id}>
                                  <td>
                                  <a onClick={() => this.onHisoryClick.call(this, inventory.id)}>
                                  {inventory.name}
                                  </a>
                                  </td>
                                  <td>
                                    {moment(
                                      inventory.dateCreated.toDate(),
                                    ).calendar()}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {inventory.promoted ? (
                                      <i>Promoted</i>
                                    ) : (
                                      <i
                                        onClick={() => {
                                          this.promote(inventory);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        className="material-icons">
                                        add
                                      </i>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        </div>
                        { inventories && inventories.length > 0 && 
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-center">
                            <li className={this.state.currentPage === 0 ? "page-item disabled" : "page-item"}>
                              <a onClick={() => this.setState({currentPage: this.state.currentPage-1})} className="page-link">
                                Previous
                              </a>
                            </li>
                            {
                              this.getAllPagesIndex(inventories).map((value, id) => 
                              <li key={id}  

                              className="page-item">
                              <a style={{ ...this.state.currentPage === id && {backgroundColor: '#E0F7E0'}}} 
                              onClick={() => this.setState({currentPage: id})} className="page-link">
                                {value}
                              </a>
                            </li>)
                            }
                            <li className={this.state.currentPage === parseInt((inventories.length/PAGE_SIZE)) ? "page-item disabled" : "page-item"}>
                              <a onClick={() => this.setState({currentPage: this.state.currentPage+1})} className="page-link" >
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CreateItemList />
              <div className="d-md-down-none d-block d-sm-none fix-close-btn">
			        	<button type="button" 
                      className="blue-btn"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-toggle="modal"
                      data-target="#addNewInventoryModal">
		         			Create Inventory list
				</button>
			</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container center">
          <p>Loading cafes...</p>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    promote: (inventory) => dispatch(promote(inventory)),
  };
};

const mapStateToProps = (state) => {
  //  console.log('state: ', state)
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    inventoryLists: state.firestore.ordered.inventoryLists,
    inventories: state.firestore.ordered.inventories,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }
    // console.log("defaultCafe: ", props.profile.defaultCafeId);

    return [
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "inventoryList" }],
        storeAs: "inventoryLists",
      },
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "inventories", orderBy: ["dateCreated", "desc"]}],
        storeAs: "inventories",
      },
    ];
  })
)(InventoryLists);
