import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { NavLink } from "react-router-dom";
import PriceModal from "./PriceModal";
import M from "materialize-css";
import { doneShopping } from "../../store/actions/shoppingActions";
import { getProductMinPrice } from "../../store/actions/productActions";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import { getAllProductMinimum, getStatusColorClass} from "../utils/HelperFunctions";
class ConductShopping extends React.Component {
  constructor(props) {
    super(props)

    this.priceModalRef = React.createRef()
  }

  state = {
    set: false,
  };

  componentDidMount() {
    this.container.addEventListener("DOMContentLoaded", this.handler());
  }

  componentWillUnmount() {
    this.container.removeEventListener("DOMContentLoaded", this.handler());
  }

  handler = () => {
    const elems = this.container.querySelectorAll(".modal");
    M.Modal.init(elems, {});
  };

  componentDidUpdate() {
    if (!this.state.set && this.props.shopping && this.props.prices) {
      const list = this.props.shopping.list.map(item => {
        const minPrice = getAllProductMinimum(item, this.props.prices);
        return {
        ...item,
        oldPrice: minPrice,
        price: minPrice
      }})
      this.setState({ shopping: {...this.props.shopping, list: list}, set: true, suppliers: this.props.suppliers });
    }
  }

  save(item) {
    const shopping = JSON.parse(JSON.stringify(this.state.shopping));
    shopping.list[item.i] = item;
    this.setState({ shopping });
  }

  priceModalClick(i){
    this.state.shopping.list[i].productIds &&
    this.state.shopping.list[i].productIds[0] &&
       this.props.getProductMinPrice(this.state.shopping.list[i].productIds[0])
    this.setState({ selected: i })
  }

  doneShopping() {
    const { shopping } = this.state;
    const newList = [];
    const archiveList = [];
    const priceList = [];
    shopping.list.forEach((elem) => {
      !elem.checked ? newList.push(elem) : archiveList.push(elem);
    });
    archiveList.forEach((itm) => {
      //If price not change, we don't have to put any new doc in prices
      if(itm.oldPrice !== itm.price){
      if(itm.productIds && itm.productIds.length > 0)
        priceList.push({price: itm.price, productId: itm.productIds[0], supplierId: this.props.location.state.supplierId})
      else
        priceList.push({price: itm.price, itemId: itm.itemId, supplierId: this.props.location.state.supplierId})
      }
    })

    
    console.log(priceList);
    console.log(archiveList);
    this.props.doneShopping(newList, archiveList, priceList);
  }

  onChange(type, value) {
    const shopping = JSON.parse(JSON.stringify(this.state.shopping));
    shopping.list[this.state.selected][type] = value;
    this.setState({ shopping });
  }

  checkItem(i, checked) {
    // Hotfix for TypeError: Cannot add property checked, object is not extensible
    const shopping = JSON.parse(JSON.stringify(this.state.shopping));
    shopping.list[i].checked = checked;
    this.setState({ shopping });
  }

  render() {
    const { shopping, selected } = this.state;
    const { suppliers, products } = this.props;
    // console.log("suppliers" , suppliers)
    
    return (
      <div>
        <Navbar />
        <Sidebar />
      <div className="main-panel">
			<div className="content">
				<div className="page-inner">
					<div className="page-header">
						<h4 className="page-title">Shopping for</h4>
						<div className="btn-group btn-group-page-header ml-auto">
              <div ref={(ref) => (this.container = ref)} className="container">
                <div className="section">
                  <NavLink to="/shopping">
                    <button
                      onClick={() => this.doneShopping()}
                      className="blue-btn d-none d-sm-block"
                    >
                      Done shopping
                    </button>
                  </NavLink>
                </div>
              </div>
						</div>
					</div>

          <div className="row">
						<div className="col-md-12">
							<div className="card">
								<div className="card-header">
									<div className="card-title">My Weekly inventory list</div>
								</div>
							<div className="card-body pb-0">
                <ul className="mobile-view d-md-down-none d-block d-sm-none">
                {shopping?.list?.map((item, i) => {
                    return ( 
                      <li key={i}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="item w-100">
                            <p className="title">
                              <label className="list-chk list-chk-mobile">
                                {item.name}
                                   <input
                              onChange={(e) =>{
                                this.checkItem(i, e.target.checked);
                              }}
                              checked={item.checked || false}
                              type="checkbox" name="itemAll" className="inventory-chk" id="itemAll"
                            />
                            <span className="checkmark"></span>
                              </label>
                            </p>
                            <p className="content d-flex justify-content-between">
                              <span>{item.category || ' '}</span>
                              <span>
                              <a
                            ref={this.priceModalRef}
                            data-target="#modal2"
                            data-toggle="modal"
                            className="modal-trigger"
                          />
                          <button
                            onClick={this.priceModalClick.bind(this, i)}
                            data-target="#modal2"
                            data-toggle="modal"
                            className="waves-effect waves-light btn pink lighten-1 z-depth-0 modal-trigger"
                          >
                          {/* {item.pr} */}
                            {item.price ? `$${item.price}` : "---"}
                          </button>
                              </span>
                              <span>
                              {item && (
                                    <button
                                      className={getStatusColorClass(
                                        item.status,
                                      )}>
                                      {item.status || 'OK'}
                                    </button>
                                  )}
                              </span>
                            </p>
                          </div>
                        </div>
                      </li>
                    )})}
                  </ul>
                <div className="d-none d-sm-block">
									<table className="table inventory-list">
                  <thead>
                    <tr>
                    <th>
                      <label className="" style={{marginLeft: 40}}>Item name
                          {/* <input type="checkbox" name="itemAll" id="itemAll" /> */}
                          {/* <span className="checkmark"></span> */}
                        </label>
                        </th>
                        <th>Category</th>
                        <th>Price</th>
                        <th style={{width: '175px'}}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    {shopping?.list?.map((item, i) => {
                    return ( <tr key={i}>
                        <td>
                          <label className="list-chk">{item.name}
                            <input
                              onChange={(e) =>{
                                this.checkItem(i, e.target.checked);
                              }}
                              checked={item.checked || false}
                              type="checkbox" name="itemAll" className="inventory-chk" id="itemAll"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item.category}
                        </td>
                        <td>
                          <a
                            ref={this.priceModalRef}
                            data-target="#modal2"
                            data-toggle="modal"
                            className="modal-trigger"
                          />
                          <button
                            onClick={this.priceModalClick.bind(this, i)}
                            data-target="#modal2"
                            data-toggle="modal"
                            className="waves-effect waves-light btn pink lighten-1 z-depth-0 modal-trigger"
                          >
                          {/* {item.pr} */}
                            {item.price ? `$${item.price}` : "---"}
                          </button>
                        </td>
                        <td>
                        {item && (
                                    <button
                                      className={getStatusColorClass(
                                        item.status,
                                      )}>
                                      {item.status || 'OK'}
                                    </button>
                                  )}
                        </td>
                      </tr>
                    )})}
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
          <PriceModal
            save={this.save.bind(this)}
            onChange={this.onChange.bind(this)}
            selected={selected}
            shopping={shopping}
            suppliers={suppliers}
            products={products}
            selectedSupplier={this.props.location.state.supplierId}
          />
        </div>
      </div>
      </div>
      </div>
      <div className="d-md-down-none d-block d-sm-none fix-close-btn">
          <NavLink to="/shopping">
            <button
              type="button"
              className="blue-btn long-btn"
              aria-haspopup="true"
              onClick={() => this.doneShopping()}>
              Done Shopping
            </button>
          </NavLink>
      </div>
    </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doneShopping: (newList, archivedList, priceList) =>
      dispatch(doneShopping(newList, archivedList, priceList)),
    getProductMinPrice: (productId) =>
      dispatch(getProductMinPrice(productId)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    shopping: state.firestore.data.shopping,
    suppliers: state.firestore.data.suppliers && Object.entries(state.firestore.data.suppliers),
    prices: state.firestore.data.prices,
    products: state.firestore.data.products
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }
    return [
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "shoppingLists", doc: "main" }],
        storeAs: "shopping",
      },
      {
        collection: "suppliers",
        storeAs: "suppliers",
      },
      {
        collection: "prices",
        storeAs: "prices",
      },
      {
        collection: "products",
        storeAs: "products",
      },
    ];
  })
)(ConductShopping);
