import React, { Component } from "react";
import '../../assets/css/home.css';
import { NavLink } from "react-router-dom";

class Home extends Component {
  componentWillMount() {
    document.getElementById("body").className = "homebody";
  }

  render() {
    //    const { auth, authError } = this.props;
    //    if (auth.uid) return <Redirect to="/" />;

    return (
      <div>
        <section id="header">
        <div className="myContainer">
          <nav className="navbar navbar-expand-lg ">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" 
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
										<img src={require('../../assets/img/icons/menu-icon.png')}/>
								</button>
								<a className="navbar-brand" href="#"><h1 className="mr-5">CafeCohort</h1></a>
								<div className="collapse navbar-collapse" id="navbarNav">
									<div className="text-right">
                    <button className="navbar-toggler close-btn" type="button" data-toggle="collapse" 
                      data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
											×
										</button>
									</div>
										
										<div className="loginBtn mt-5 d-md-down-none d-block d-sm-none">
											<NavLink to="/signin">
                        <button className="myButton">Log in</button>
                      </NavLink>
											<NavLink to="/signup">
                        <button className="myButton bondiBlueBg" style={{marginLeft: '19px'}}>Sign Up</button>
                      </NavLink>
									</div>
								</div>
								<div className="loginBtn d-flex">
										<NavLink to="/signin"><button className="myButton">Log in</button></NavLink>
										<NavLink to="/signup">
                      <button className="myButton bondiBlueBg d-none d-sm-block" style={{marginLeft: '19px'}}>Sign Up</button>
                    </NavLink>
								</div>
						</nav>
          <div className="row">
                <div className="col-lg-5 d-flex h-100 align-item-center justify-content-between mb-4 flex-column">
                    <h1 className="text-white pb-3 xs-center">Manage your inventory and shopping tasks better.</h1>
                    <p className="text-white text-lg xs-center">CafeCohort is building a better inventory, shopping, and pricing app and it's FREE to you.</p>
                    <div className="getBtn xs-center">
                        <button className="myButton bondiBlueBg mt-3">Get Started Now</button>
                    </div>
                </div>
                <div className="col-lg-7" style={{marginRight: '-30px'}}>
                  <img src={require('../../assets/img/home/dashboard.png')} className="img-responsive" />
                </div>
            </div>
          </div>
        </section>
        <section id="section1">
				<div className="row myContainer">
						<div className="col-lg-5 mb-4">
								<h1>Inventory</h1>
								<p className="text-muted">We simplify the routine task of taking inventory and make it better, more accurate, and more useful.</p>
								<div className="better ml-5">
										<div className="d-flex">
												<div><img src={require('../../assets/img/home/window.png')} alt="" className="window"/></div>
												<p>Conduct inventory directly from any phone or tablet</p> 
										</div>
										<div className="d-flex">
												<div><img src={require('../../assets/img/home/window.png')} alt="" className="window"/></div>
												<p>Creat custom inventory lists for FOH and BOH</p>
										</div>
										<div className="d-flex">
												<div><img src={require('../../assets/img/home/window.png')} alt="" className="window"/></div>
												<p>Low stock items automatically added to your shopping list</p>
										</div>
								</div>
						</div>
						<div className="col-lg-7">
								<img src={require('../../assets/img/home/home_bg2.png')} className="img-responsive img-round box-shadow"/>
						</div>
				</div>
		    </section>
        <section id="section2">
        <div className="row mt-5 pt-5">
            <div className="col-lg-7 d-none d-sm-block">
              <img src={require('../../assets/img/home/shopping_bg.png')} className="img-responsive img-round"/>
            </div>
            <div className="col-lg-5">
                <h1>Shopping List</h1>
                <p className="text-muted">No more paper list, text message list, or pictures of lists.  Keep one smart shoppig list that's up to date and accurate.</p>
                <div className="better">
                    <div className="d-flex">
                        <div><img src={require('../../assets/img/home/window.png')} alt="" className="window"/></div>
                        <p>Your list is always update to date with the last inventory results</p> 
                    </div>
                    <div className="d-flex">
                        <div><img src={require('../../assets/img/home/window.png')} alt="" className="window"/></div>
                        <p>Check off items as you go so you don't forget anything</p>
                    </div>
                    <div className="d-flex">
                        <div><img src={require('../../assets/img/home/window.png')} alt="" className="window"/></div>
                        <p>Keep notes on what you buy and track prices so you can make the best choices</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 d-md-down-none d-block d-sm-none">
              <img src={require('../../assets/img/home/shopping_bg.png')} className="img-responsive img-round" style={{marginLeft: '-20px'}}/>
            </div>
          </div>
        </section> 
        <section id="section3">
				<h1 className="Inventory">Price Awareness</h1>
				<p className="sell2 text-muted">Prices can vary a lot from brand to brand or warehouse to warehouse.  We help you track these price difference in a meaningful way so you can make the best buying decision and save money.</p>
				<img src={require('../../assets/img/home/product-detail.png')} className="img-responsive img-round mt-5 box-shadow" style={{maxWidth: '900px', position: 'relative', zIndex: '10'}} />
		  </section>

     {/* FEEDBACK START --> */}
		<section id="feedback">
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					{/* Indicators */}
					<ol className="carousel-indicators">
						<li data-target="#myCarousel" data-slide-to="0" className="active"></li>
						<li data-target="#myCarousel" data-slide-to="1"></li>
					</ol>

					{/* Wrapper for slides */}
					<div className="carousel-inner">
          <div className="carousel-item active">
              <div>
                  <h1 className="what">What our clients say about us</h1>   
                   <div className="d-flex ">
                       <div className="replyCenter">
                           <div className="reply">
                               <em>"I used to make 3-4 extra shopping trips a week because of poor inventory sweeps and communication.  CafeChort has eleminated that almost entirely."
                              </em>
                           </div>
                           <div className="katie">- V SHAH - </div>
                           <div className="culinary">Owner and Operator, Murphy's Pub</div>
                       </div>
                   </div>
              </div>
            </div>
          
            <div className="carousel-item">
              <div>
                  <h1 className="what">What our clients say about us</h1>   
                   <div className="d-flex ">
                       <div className="replyCenter">
                           <div className="reply">
                               <em>"I love how the app keeps track of prices and product notes.  Now I can send my employee to do the shopping for me and know he will come back with the right product."
                              </em>
                           </div>
                           <div className="katie">- L. VUU - </div>
                           <div className="culinary">Owner and Operator, Caffè Torino</div>
                       </div>
                   </div>
              </div>
            </div>
						
					</div>

					{/* Left and right controls */}
					<a className="carousel-control-prev carousel-control d-none d-sm-block" href="#myCarousel" data-slide="prev">
						<span className="carousel-control-prev-icon"></span>
					</a>
					<a className="carousel-control-next carousel-control d-none d-sm-block" href="#myCarousel" data-slide="next">
						<span className="carousel-control-next-icon"></span>
					</a>
				</div>
		</section>
		{/* FEEDBACK END */}
    {/* FOOTER START */}
		<section id="footer">
				<div className="myContainer d-flex justify-content-between align-item-center row d-md-down-none d-block d-sm-none">
					<div className="socialIcons text-center w-100">
							<a href="#" className="fa fa-facebook"></a>
							<a href="#" className="fa fa-twitter"></a>
							<a href="#" className="fa fa-instagram"></a>
					</div>
					<div className="text-center w-100 mt-3"> 
						<div className="copyright middleGray font14_500" style={{lineHeight: '2.5'}}>
              © 2021 CafeCohort &nbsp;&nbsp;&nbsp;&nbsp; 
              <br/> 
              Terms of Service &nbsp;&nbsp;&nbsp;&nbsp; 
              <br/> 
              <span aria-haspopup="true" aria-expanded="false" data-toggle="modal" data-target="#createModal" style={{cursor: 'pointer'}}>
                Privacy Policy</span> &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
					</div>
				</div>
				<div className="d-none d-sm-block">
					<div className="myContainer d-flex justify-content-between align-item-center row">
						<div className="col-lg-8"> 
							<div className="copyright middleGray font14_500" style={{lineHeight: '3.5'}}>© 2021 CafeCohort &nbsp;&nbsp;&nbsp;&nbsp; Terms of Service &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;   <span aria-haspopup="true" aria-expanded="false" data-toggle="modal" data-target="#createModal" style={{cursor: 'pointer'}}>Privacy Policy</span> &nbsp;&nbsp;&nbsp;&nbsp;</div>
						</div>
						<div className="col-lg-4">
							<div className="socialIcons">
									<a href="https://twitter.com/CafeCohort" className="fa fa-facebook"></a>
									<a href="https://twitter.com/CafeCohort" className="fa fa-twitter"></a>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="createModal" role="dialog">
            <div className="modal-dialog">
            {/*  Modal content */}
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="page-title mt-3">Privacy Policy</h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        We do not share your personal data.
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn primary-contained-btn" data-dismiss="modal">Cancel</button>
                        
                    </div>
                </div>
            </div>
        </div>
		</section>
		{/*  FOOTER END */ }
		</div>
    );
  }
}

export default Home;
