import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { addItemToList } from '../../store/actions/shoppingActions';

class AddUPCDialog extends React.Component {
  constructor(props) {
    super(props);

    this.button = React.createRef();
  }

  state = {
    searchText: '',
  };

  searchTextChange = (event) => {
    this.setState({ searchText: event.target.value });
  };

  render() {
    const { products } = this.props;
    return (
      <div id="addNewUPCmodal" className="modal fade" role="dialog">
        <div className="modal-dialog add-new-item-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Product Lookup</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div
              className="modal-body"
              style={{ height: 500, overflow: 'scroll' }}>
              <div className="d-md-down-none d-block d-sm-none">
                <div className="search-box">
                  <input
                    className="form-control height-inherit"
                    placeholder="Type name of an item"
                    value={this.state.searchText}
                    onChange={this.searchTextChange.bind(this)}
                  />
                </div>
              </div>

              <div className="d-none d-sm-block">
                <div className="width-minor mb-4 two-select-group mt-2 pb-1 flex-wrap ">
                  <div className="search-box">
                    <input
                      className="form-control height-inherit"
                      placeholder="Search products or brand"
                      value={this.state.searchText}
                      onChange={this.searchTextChange.bind(this)}
                    />
                    {/* <button className="blue-btn" style={{width: 200, marginLeft: 10 }}>Add item</button> */}
                  </div>
                </div>
              </div>
              {/* Mobile Vew */}
              <div className="d-md-down-none d-block d-sm-none">
              <ul className="mobile-view ">
              {products?.filter((item) => this.props.addedProducts && !this.props.addedProducts.includes(item.id)).map((item, i) => {
                    let found = true;
                    if (this.state.searchText !== '') {
                      found = item.description
                        ?.toLowerCase()
                        .includes(this.state.searchText?.toLowerCase());
                    }
                    if (found)
								return (
                  <li key={i}>
                    <div className="d-block justify-content-between align-items-center">
                      <div className="item">
                        <p className="title">{item.description}</p>
                        <p className="content d-flex justify-content-between">
                          <span>
                            {item.upc}
                          </span>
                          <span>
                            <button 
                            onClick={() => {
                                this.props.addProductToList(item);
                              }}
                               className="blue-btn " style={{minWidth: 60}}>Add</button>
                          </span>
                        </p>
                    </div>
                </div>
              </li>);
               })};
                </ul>
                </div>
              <table className="table d-none d-sm-block">
                {/* <table className="responsive-tabe highlight centered itemtable"> */}
                <thead>
                  <tr>
                    <th>Product Description</th>
                    <th>UPC</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {products?.filter((item) => this.props.addedProducts && !this.props.addedProducts.includes(item.id)).map((item, i) => {
                    let found = true;
                    if (this.state.searchText !== '') {
                      found = item.description
                        ?.toLowerCase()
                        .includes(this.state.searchText?.toLowerCase());
                    }
                    if (found)
                      return (
                        <tr key={i}>
                          <td>{item.description}</td>
                          <td>{item.upc}</td>
                          <td>
                            <button
                              onClick={() => {
                                this.props.addProductToList(item);
                              }}
                              style={{ cursor: 'pointer' }}
                              className="blue-btn">
                              Add
                            </button>
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
            {!this.props.hideBtns && <div className="modal-footer" style={{ paddingTop: 20 }}>
              <button
                type="button"
                ref={this.button}
                className="close-btn"
                data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="blue-btn"
                onClick={() => {
                  this.props.onSubmit();
                  this.button.current.click();
                }}>
                Create Product
              </button>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToList: (list) => dispatch(addItemToList(list)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (AddUPCDialog);
