import React, { Component } from "react";
import { connect } from "react-redux";
import { createItemList } from "../../store/actions/itemActions";
import { Redirect } from "react-router-dom";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import '../../assets/css/bootstrap.min.css';
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";

class CreateItemList extends Component {
  constructor(props) {
    super(props)

    this.button = React.createRef()
  }
  state = {
    name: "",
    description: "",
    items: [],
    itemCount: 0,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.props.createItemList(this.state);
    this.button.current.click()
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/items" />;

    return (
      <div>
        <div id="addNewInventoryModal" className="modal fade" role="dialog">
          <div className="modal-dialog add-new-inventory-modal">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Create Inventory list</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group pr-0 pl-0">
                  <label htmlFor="listNameInput">List name</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="List name sample"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group pr-0 pl-0">
                  <label htmlFor="listDescription">List description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    rows="7"
                    onChange={this.handleChange}
                    placeholder="Sample description"></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="primary-contained-btn"
                  data-dismiss="modal"
                  ref={this.button}
                  >
                  Cancel
                </button>
                <button
                  type="button"
                  className="blue-btn"
                  onClick={this.handleSubmit}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createItemList: (itemList) => dispatch(createItemList(itemList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateItemList);
