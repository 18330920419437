import React from "react";

class Details extends React.Component {
  render() {
    //const id = props.match.params.id;
    const { items, edit, disabled } = this.props;

    return (
      <div className="row">
        <div className="col-md-12">
                    <div className="card">
          <div className="card-header" style={{paddingBottom: '30px'}}>
            <button
              disabled={edit && disabled}
              onClick={() => this.props.action()}
              className={ this.props.isSupplier ? "blue-btn" : "blue-btn d-none d-sm-block"}
            >
              {edit ? "Save" : "Edit"}
            </button>
          </div>

          <div className="card-body">
            {Object.entries(items).map(([key, { value, label }], i) => {
              return (
                <div key={i}>
                  {edit ? (
                    <div className="form-group col-md-12">
                      <label htmlFor={key}>{label} </label>
                      <input
                        id={key}
                        type="text"
                        className="form-control"
                        value={value}
                        onChange={(e) =>
                          this.props.changeValue(key, e.target.value)
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="detail-title" >
                        {label}:
                      </div>
                      <div className="detail-body">  
                        &nbsp; &nbsp;{value}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {this.props.renderExtra?.()}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default Details;
