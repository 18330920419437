import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import authReducer from "./authReducer";
import itemReducer from "./itemReducer";
import productReducer from "./productReducer";
import supplierReducer from "./supplierReducer";
import cafeReducer from "./cafeReducer";
import shoppingReducer from "./shoppingReducer";
import localReducer from './localReducer'
const rootReducer = combineReducers({
  auth: authReducer,
  product: productReducer,
  item: itemReducer,
  cafe: cafeReducer,
  supplier: supplierReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  shopping: shoppingReducer,
  local: localReducer,
});

export default rootReducer;
