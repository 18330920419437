import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { addItemToList } from '../../store/actions/shoppingActions';

class ItemDetailDialog extends React.Component {
  constructor(props) {
    super(props);

    this.button = React.createRef();
  }

  render() {
    const { item } = this.props;
    console.log(item)
    return (
      <div id="itemDetailModal" className="modal fade" role="dialog">
        <div className="modal-dialog detail-item-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Item Detail</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div
              className="modal-body"
              >
              <div className="d-sm-block">
                <div className="width-minor mb-4 two-select-group mt-2 pb-1 flex-wrap ">
                <div>
                      <div className="detail-title" >
                        Name:
                      </div>
                      <div className="detail-body">  
                        {item && item.name}
                      </div>
                    </div>
                    <div>
                      <div className="detail-title" >
                      Description:
                      </div>
                      <div className="detail-body">  
                        {item && item.description || "-"}
                      </div>
                    </div>
                    <div>
                      <div className="detail-title" >
                      Par Amount:
                      </div>
                      <div className="detail-body">  
                        {item && item.par}
                      </div>
                    </div> 
                    <div>
                      <div className="detail-title" >
                      Category:
                      </div>
                      <div className="detail-body">  
                        {item && item.category}
                      </div>
                    </div>
                </div>
              </div>
              
          </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps) (ItemDetailDialog);
