export const addItemToList = (list) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;
    const profile = getState().firebase.profile;

    const shoppingRef = firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("shoppingLists")
      .doc("main");

    shoppingRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        shoppingRef
          .update({
            list,
            updatedBy: authorId,
            dateUpdated: new Date(),
          })
          .then(() => {
            dispatch({ type: "CREATE_SHOPPING_ITEM_SUCCESS" });
          })
          .catch((err) => {
            dispatch({ type: "CREATE_SHOPPING_ITEM_ERROR" }, err);
          });
      } else {
        shoppingRef
          .set({
            list,
            createdBy: authorId,
            dateCreated: new Date(),
          })
          .then(() => {
            dispatch({ type: "CREATE_SHOPPING_ITEM_SUCCESS" });
          })
          .catch((err) => {
            dispatch({ type: "CREATE_SHOPPING_ITEM_ERROR" }, err);
          });
      }
    });
  };
};

export const doneShopping = (newList, archiveList, priceList) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;
    const profile = getState().firebase.profile;
    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("shoppingLists")
      .doc("main")
      .update({
        updatedBy: authorId,
        dateUpdated: new Date(),
        list: newList,
      })
      .then(() => {
        if (archiveList.length > 0) {
          firestore
            .collection("cafes")
            .doc(profile.defaultCafeId)
            .collection("shoppingLogs")
            .add({
              createdBy: authorId,
              dateCreated: new Date(),
              list: archiveList,
            })
            .then(() => {
        if (priceList.length > 0) {
              priceList.forEach(itm => {
                console.log("itm" , itm);
                firestore
                    .collection('prices')
                    .add({
                      ...itm,
                      createdBy: authorId,
                      dateCreated: new Date(),
                    })
                    .then(() => {
                      dispatch({
                        type: 'DONE_SHOPPING_SUCCESS',
                      });
                    })
                    .catch((err) => {
                      dispatch({ type: 'DONE_SHOPPING_ERROR' }, err);
                    });
              firestore
                .collection("cafes")
                .doc(profile.defaultCafeId)
                .collection("inventoryItems")
                .doc(itm.id)
                .update({
                  price: itm.price,
                  dateUpdated: new Date(),
                })
                .then(() => {
                  dispatch({
                    type: "DONE_SHOPPING_SUCCESS",
                  });
                })
                .catch((err) => {
                  dispatch({ type: "DONE_SHOPPING_ERROR" }, err);
                });
              })
            }
            else{
              dispatch({
                type: 'DONE_SHOPPING_SUCCESS',
              });
            }
            })
            .catch((err) => {
              dispatch({ type: "DONE_SHOPPING_ERROR" }, err);
            });
        } else {
          dispatch({
            type: "DONE_SHOPPING_SUCCESS",
          });
        }
      })
      .catch((err) => {
        dispatch({ type: "DONE_SHOPPING_ERROR" }, err);
      });
  };
};

export const itemStatusChange = (newList) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;
    const profile = getState().firebase.profile;
    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("shoppingLists")
      .doc("main")
      .update({
        list: newList,
      })
      .then(() => {
        dispatch({
          type: "DONE_SHOPPING_SUCCESS",
        });
      })
      .catch((err) => {
        dispatch({ type: "DONE_SHOPPING_ERROR" }, err);
      });
  };
};
