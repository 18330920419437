import React from "react";
import { Link, NavLink } from "react-router-dom";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { connect } from "react-redux";
import { changeSidebar } from '../../store/actions/localActions'

const Navbar = (props) => {
  const { auth, profile } = props;
  const links = auth.uid ? (
    <SignedInLinks profile={profile} />
  ) : (
    <SignedOutLinks />
  );

  
  return (
    <div className="wrapper">
      <div className="main-header" data-background-color="purple">
			<div className="logo-header">
        <NavLink to="/cafe" className="logo">
          {profile.defaultCafeName}
				</NavLink>
				<button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon">
						<div className="menu-icon"></div>
					</span>
				</button>
				
				<div className="navbar-minimize">
					<button className="btn btn-minimize btn-rounded" onClick={() => props.changeSidebar(!props.sidebar)}>
						<div className="menu-icon"></div>
					</button>
				</div>
			</div>

      <nav className="navbar navbar-header navbar-expand-lg">
				
				<div className="container-fluid">
					<ul className="navbar-nav topbar-nav align-items-center">
						
						<li className="nav-item dropdown hidden-caret">
							<a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
								<div className="avatar-sm">
                {profile.initials}
								</div>
							</a>
						</li>
						<li className="nav-item name-text">
							<p>{profile.defaultCafeName}</p>
							<p>{profile.firstName} {profile.lastName}</p>
						</li>
						<li className="nav-item d-lg-none d-block" styles={{flex: 1, textAlign: 'right'}}>
							<button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon">
									<div className="menu--white-icon"></div>
								</span>
							</button>
						</li>
					</ul>

				</div>
			</nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    sidebar: state.local.sidebar,
  };
};


const mapDispatchToProps = (dispatch) => {
	return {
	   changeSidebar: (value) => dispatch(changeSidebar(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
