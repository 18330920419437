import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

class PriceModal extends React.Component {
  constructor(props) {
    super(props)

    this.button = React.createRef()
  }
  onChange(type, value) {
    this.setState({
      [type]: value,
    });
  }

  render() {
    const { shopping, selected, productUPC, suppliers } = this.props;  
    const minData = 
    shopping &&
    productUPC &&
      shopping.list[selected]?.productIds &&
      productUPC.productId == shopping.list[selected]?.productIds[0]
        ? productUPC
        : {};
    return (
      <div id="modal2" className="modal fade" role="dialog">
          <div className="modal-dialog add-new-inventory-modal">
        {selected > -1 && (
          <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{shopping.list[selected]?.name || ""}</h4>
            <button type="button" className="close" data-dismiss="modal">
                  &times;
              </button>
					</div>
					<div className="modal-body">
            {/* <p>{shopping.list[selected]?.description || ""}</p> */}
            <div className="row">
              <div className="form-group col-md-4">
                <label htmlFor="price">Price</label>
								<input type="text" className="form-control"                 
                id="price" 
                type='number'
                aria-describedby="Price" 
                step="0.01"
                onChange={(e) => this.props.onChange("price", parseFloat(e.target.value))}
                value={shopping.list[selected]?.price || ""}
                placeholder="Price"/>
              </div>
              <div className="form-group col-md-8">
              <label htmlFor="warehouse">Warehouse</label>
                <select className="form-control" id="warehouse" 
                 onChange={(e)=> {
                  this.props.onChange("supplierId", e.target.value)
                 }}
                 value={this.props.selectedSupplier}>
                  <option>Select Warehouse or Supplier </option>
                    {suppliers?.map(
                    (supplier, i) =>
                        supplier && (
                        <option key={i}
                          value={supplier[0]}>
                            {supplier[1].name.charAt(0).toUpperCase() + supplier[1].name.slice(1)}
                          </option>
                        )
                    )}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-8">
                <label>Description: </label>
                <p>{minData && minData.description || "--"}</p>
              </div>
              <div className="form-group col-4">
                <label>UPC:</label> 
                <p>{shopping.list[selected].upc || minData && minData.upc || "--"}</p>
              </div>
            </div>
            </div>
            <div className="modal-footer">
						<button type="button" ref={this.button} className="close-btn" data-dismiss="modal">Cancel</button>
						<button type="button" className="blue-btn" onClick={()=> {
              this.props.save(shopping.list[selected]);
              this.button.current.click()
            }}>Update</button>
					</div>
          </div>
        )}
      </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    productUPC: state.product.productUPC
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }
    return [
      {
        collection: "cafes",
      },
    ];
  })
)(PriceModal);
