import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, NavLink } from "react-router-dom";
import AddItemDialog from "../shopping/AddItemDialog";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import SimpleNav from "../layout/SimpleNav";
import moment from 'moment'
import { itemStatusChange } from "../../store/actions/shoppingActions";
import { getStatusColorClass, getNextStatus } from "../utils/HelperFunctions";

export const CafeDashboard = (props) => {
  const [path, setPath] = React.useState(null);
  const { profile, auth, inventoryList, shoppingList, inventories } = props;

  if (!auth.uid) return <Redirect to="/signin" />;
  if (path) return <Redirect to={path} />;


  const updateItemStatus = (status, id) => {
    const newStatus = getNextStatus(status)
    console.log(typeof(shoppingList.list))
    const newList = shoppingList.list.map((elem) => {
    return {
      ...elem,
      ...id === elem.id && {status: newStatus}
    }});
    props.itemStatusChange(newList);
  }

const onHisoryClick = (key) =>{
   props.history.push(`/inventory/${key}/conduct`)
}
  if (profile) {
    if (profile.defaultCafeName) {
      return (
        <div> 
          <Navbar />
          <Sidebar />
        <div className="main-panel">
          <div className="content">
          <div className="page-inner">
            <div className="page-header">
              <h4 className="page-title">Dashboard</h4>
            </div>

          <div className="row">
						<div className="col-md-12">
							<div className="card">
								<div className="card-header mb-3">
									<div className="d-flex justify-content-between flex-wrap">
                    <h4 className="card-title mb-2">Current Shopping List</h4>
                    <div>
                      <button
                        data-toggle="modal"
                        data-target="#addNewItemShoppingmodal"
                        className="outline-blue-btn modal-trigger">
                        Add new item
                      </button>
                    </div>  
                  </div>
                </div>

                {/* Begin Mobile view */}
                <div className="card-body pb-0 d-md-down-none d-block d-sm-none">
									<ul className="mobile-view">
                    {shoppingList && shoppingList.list ? (
                      Object.entries(shoppingList.list).map(([key, value]) => {
                        return (
                          <li key={key}>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="item w-100">
                                  <p className="title">{value?.name}</p>
                                  <p className="content d-flex">
                                    <span>{value?.par}</span>
                                </p>
                              </div>
                              {value && 
                                <button  
                                  onClick={()=> updateItemStatus(value.status, value.id)}
                                  className={getStatusColorClass(value.status)}>
                                  { value.status || "OK"}
                                </button>
                              }
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li>
                        <p>The shopping list is empty.</p>
                        <p></p>
                        <p></p>
                      </li>
                    )}
									</ul>
								</div>
                {/* End Mobile view */}
                
                {/* Begin Desktop view */}
                <div className="card-body pb-0 shopping-list-block d-none d-sm-block">
                  <table className="table shopping-list">
										<thead>
											<tr>
												<th>Item name</th>
												<th>Par</th>
                        <th style={{width: '20%'}}>Status</th>
											</tr>
										</thead>

                  <tbody>
                    {shoppingList && shoppingList.list ? (
                      Object.entries(shoppingList.list).map(([key, value]) => {
                        return (
                          <tr key={key}>
                            <td>{value?.name}</td>
                            <td>{value?.par}</td>
                            <td className="list-status">
                            {value && <button  
                          onClick={()=> updateItemStatus(value.status, value.id)}
                          className={getStatusColorClass(value.status)}>
                          { value.status || "OK"}
                          </button>
                           }
                              <button className="shopping-arrow">
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>The shopping list is empty.</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          
          </div>

          <div className="col-md-12">
							<div className="card">
								<div className="card-header">
									<div className="card-title">Inventory Tasks</div>
								</div>

								<div className="card-body pb-0">

                  {/* Begin Mobile view */}
									<ul className="mobile-view d-md-down-none d-block d-sm-none">
                    {inventoryList && Object.entries(inventoryList).map(([key, item]) => 
                  	  <li key={key}>
												<div className="d-flex justify-content-between align-items-center" 
                          onClick={() =>
                            setPath(`/inventory/${key}/conduct/${true}`) 
                          }>
													<div className="item">
														<p className="title">{item.name}</p>
														<p className="content">
															<span>{moment(
                                item.dateCreated.toDate(),
                                ).calendar()}
                              </span>
			                      </p>
			                    </div>
			                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
												</div>
										  </li>
                              )}
									</ul>
                  {/* End Mobile view */}
                
                  {/* Begin Desktop view */}
									<div className="d-none d-sm-block">
										<table className="table history-list-table">
											<thead>
												<tr>
													<th>List Name</th>
													<th>Item Count</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
                      {inventoryList &&
                              Object.entries(inventoryList).map(([key, item]) => 
												<tr key={key}>
													<td>{item.name}</td>
													<td className="list-status">
	                            
                              
                              <span> {item.itemCount}
                                    </span>
                                    </td>
                                    <td>
                                      
                           <button  onClick={() => onHisoryClick.call(this, key)}>
	                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
	                             </button>
													</td>
												</tr>
                        )}
                      
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
          </div>

            <AddItemDialog shopping={shoppingList} />

        </div>

        </div>
        </div>
        </div>
      );
    } else {
      return (
        <div>
          <SimpleNav/>
          <div className="container section bk-title-card" style={{marginTop: '20px'}}>
            <div className="card z-depth-0">
              <div className="card-content">
                <span className="card-title">Welcome to CafeChort. Please </span>
                <NavLink to="/createCafe" className="card-title">Create a Cafe</NavLink>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="container center">
        <p>Loading Profile...</p>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    itemStatusChange: (list) => dispatch(itemStatusChange(list)),
  };
};


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    users: state.firestore.ordered.users,
    inventoryList: state.firestore.data.inventoryList,
    shoppingList: state.firestore.data.shoppingList,
    inventories: state.firestore.data.inventories,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }

    return [
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "inventoryList" }],
        storeAs: "inventoryList",
      },
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "shoppingLists", doc: "main" }],
        storeAs: "shoppingList",
      },
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "inventories", orderBy: ['dateCreated', 'desc']}],
        storeAs: "inventories",
      },
    ];
  })
)(CafeDashboard);
