import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from '../../store/actions/authActions';
import { CAFE_PATH,INVEN_LIST_PATH, ITEMS_PATH, PRODUCT_PATH, SHOPPING_PATH, SUPPLIERS_PATH } from "../utils/Constants";

const Sidebar = (props) => {
  const { auth, profile } = props;
  const  path = useLocation().pathname;
  const tabs = auth.uid ? (
    <ul className="nav">
			<li className={path === CAFE_PATH ? "nav-item active" : "nav-item"}>
              <NavLink to={CAFE_PATH}>
								<div className="dashboard-icon"></div>
								<p>Dashboard</p>
              </NavLink>
						</li>
			<li className={path === SHOPPING_PATH ? "nav-item active" : "nav-item"}>
              <NavLink to={SHOPPING_PATH}>
								<div className="shopping-icon"></div>
								<p>Shopping List</p>
							</NavLink>
						</li>
			<li className={path === INVEN_LIST_PATH ? "nav-item active" : "nav-item"}>
              <NavLink to={INVEN_LIST_PATH}>
								<div className="inventory-icon"></div>
								<p>Inventory List</p>
							</NavLink>
						</li>
			<li className={path === ITEMS_PATH ? "nav-item submenu active" : "nav-item submenu"}>
              <NavLink to={ITEMS_PATH} >
								<div className="items-icon"></div>
								<p style={{flex:1}}>Cafe Items</p>
								   <div data-toggle="collapse" data-target="#forms"  aria-controls="forms">
								<span className="caret"></span>
								</div>
							</NavLink>
							<div className={ path === PRODUCT_PATH || path === ITEMS_PATH ? "collapse show" : "collapse"} id="forms">
								<ul className={path === PRODUCT_PATH || path === ITEMS_PATH ? "nav nav-collapse active" : "nav nav-collapse"}>
									<li>
										<NavLink to={PRODUCT_PATH}>
											<div className="products-icon sub-item"></div>
											<span>All Products</span>
										</NavLink>
									</li>
									
								</ul>
							</div>
						</li>
			<li className={path === SUPPLIERS_PATH ? "nav-item active" : "nav-item"}>
              <NavLink to={SUPPLIERS_PATH}>
								<div className="supplier-icon"></div>
								<p>Suppliers</p>
							</NavLink>
						</li>
					</ul>
  ) : (
    <div />
  );

  return (
    <div className="sidebar"  id="navbarSupportedContent">
			<div className="d-lg-none d-block">
				<div className="logo-header">
					<a href="index.html" className="logo">
						Cafe Cohort
					</a>
					<button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon">
							<div className="menu-icon"></div>
						</span>
					</button>
					
					<div className="navbar-minimize">
						<button className="btn btn-minimize btn-rounded">
							<div className="menu-icon"></div>
						</button>
					</div>
				</div>
			</div>
			<div className="sidebar-background"></div>
			<div className="sidebar-wrapper scrollbar-inner sides-view">
			<div className="sidebar-content flex-full">
			{tabs}
			</div>
			<ul className="nav pb-1">
			<hr styles={{borderWidth: '2px'}} />
						<li className="nav-item">
							<a href="# " onClick={props.signOut}>
								<div className="logout-icon"></div>
								<p>Log out</p>
							</a>
						</li>
			</ul>
          </div>
        </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
