import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signUp } from '../../store/actions/authActions';
import { NavLink } from "react-router-dom";
import '../../assets/css/simple.css';

class SignUp extends Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state);
    this.props.signUp(this.state);
  };
  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to="/cafe" />;

    return (
      <div>
        <section id="header-account">
          <div className="myContainer"> 
            <nav className="navbar navbar-expand-lg ">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" 
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <img src={require('../../assets/img/icons/menu-icon.png')}/>
              </button>
              <a className="navbar-brand" href="/"><h1 className="mr-5">CafeCohort</h1></a>
              <div className="collapse navbar-collapse" id="navbarNav">
                <div className="text-right">
                  <button className="navbar-toggler close-btn" type="button" data-toggle="collapse" 
                    data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    ×
                  </button>
                </div>
    
                <div className="loginBtn mt-5 d-md-down-none d-block d-sm-none">
                  <NavLink to="/signin">
                    <button className="myButton">Log in</button>
                  </NavLink>
                  <NavLink to="/signup">
                    <button className="myButton bondiBlueBg" style={{marginLeft: '19px'}}>Sign Up</button>
                  </NavLink>
                </div>
              </div>

              <div className="loginBtn d-flex">
                  <NavLink to="/signin"><button className="myButton bondiBlueBg d-none d-sm-block">Log in</button></NavLink>
                  <NavLink to="/signup">
                    <button className="myButton bondiBlueBg d-none d-sm-block" style={{marginLeft: '19px'}}>Sign Up</button>
                  </NavLink>
              </div>
            </nav>
          </div>            
        </section>


        <section id="signup">
          <form onSubmit={this.handleSubmit} className="white">
            <h5 className="section-title">Sign Up</h5>

            <div className="input-field">
              <label htmlFor="email"><p>Email</p></label>
              <input type="email" id="email" onChange={this.handleChange} />
            </div>

            <div className="input-field">
              <label htmlFor="password"><p>Password</p></label>
              <input type="password" id="password" onChange={this.handleChange} />
            </div>

            <div className="input-field">
              <label htmlFor="firstName"><p>First Name</p></label>
              <input type="text" id="firstName" onChange={this.handleChange} />
            </div>

            <div className="input-field">
              <label htmlFor="lastName"><p>Last Name</p></label>
              <input type="text" id="lastName" onChange={this.handleChange} />
            </div>
            <div className="input-field">
              <button className="myButton bondiBlueBg d-none d-sm-block">Sign Up</button>
              <div className="red-text center">
                {authError ? <p> {authError}</p> : null}
              </div>
            </div>
          </form>
        </section>

        <section id="footer-simple"></section>
      
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
