import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { updateItem, createNewPrice } from '../../store/actions/itemActions';
import EditableDetails from '../utils/EditableDetails';
import moment from 'moment';
import Navbar from '../layout/Navbar';
import Sidebar from '../layout/Sidebar';
import AddUPCDialog from './AddUPCDialog'

class ItemDetails extends React.Component {
  state = {
    set: false,
    edit: false,
    showCategories: false,
    newPrices: [],
  };

  componentDidUpdate() {
    const { inventoryItem, prices, products } = this.props;
    if (
      !this.state.set &&
      inventoryItem &&
      prices &&
      products &&
      inventoryItem.name
    ) {
      this.setState({
        set: true,
        category: this.props.inventoryItem?.category,
        items: {
          name: {
            value: this.props.inventoryItem?.name,
            label: 'Name',
          },
          description: {
            value: this.props.inventoryItem?.description,
            label: 'Description',
          },
          par: {
            value: this.props.inventoryItem?.par,
            label: 'Par amount',
          },
        },
      });

      // @TODO We are downloading all prices and filtering for the prices we want here.
      // This will be a very expensive way approach once we get thousands of prices.
      if (prices.length > 0) {
        const newList = [];
        if (inventoryItem.productIds && inventoryItem.productIds.length > 0) {
          for (let i = 0; i < inventoryItem.productIds.length; i++) {
            const element = inventoryItem.productIds[i];
            const product = this.props.products.filter(
              (value) => value.id === element,
            );
            const newPric = prices.filter(
              (value) => value.productId === element,
            );
            if (product.length > 0)
              newList.push({ product: product[0], prices: newPric, newPrice: {} });
          }
          this.setState({ productPrices: newList });
        }
        if (this.props.itemId) {
          const newPric = prices.filter(
            (value) => value.itemId === this.props.itemId,
          );
          this.setState({
            itemPrices: { item: inventoryItem, prices: newPric, newPrice: {}  },
          });
        }
      }
    }
  }

  addProductToList = (item) => {
    const { itemId } = this.props;
    this.props.updateItem(itemId, {...this.props.inventoryItem, productIds: [...this.props.inventoryItem.productIds , item.id]});
    const p = this.state.productPrices || [];
    const newPric = this.props.prices.filter(
      (value) => value.productId === item.id,
    );
    this.setState({productPrices: [...p, {product: item, prices: newPric, newPrice: {}}]})
  }

  updateItem() {
    const { itemId } = this.props;

    let item = {};
    Object.entries(this.state.items).forEach(([key, { value }]) => {
      item = { ...item, [key]: value };
    });

    if (this.state.edit)
      this.props.updateItem(itemId, { ...item, category: this.state.category });
    this.setState((prev) => ({ edit: !prev.edit }));
  }

  changeValue(key, value) {
    const { items } = this.state;
    items[key].value = value;

    this.setState({ items });
  }

  addPriceToList (i){
    const newPriceList = this.state.productPrices
    const newPrice = newPriceList[i]
    //Price and Supplier Check for adding new Price
    if(newPrice.newPrice && 
      newPrice.newPrice.supplierId && 
      newPrice.newPrice.supplierId !== "1" && 
      newPrice.newPrice.price && 
      newPrice.newPrice.price !== ""
      )
    {
    const price = {
      ...newPrice.newPrice, 
      productId: newPrice.product.id,
      productDescription: newPrice.product.description,
    }
//    console.log('newPrice: ', newPrice);
//    console.log('price: ', price);
    newPriceList[i].prices.splice(0,0,price);
    this.props.createNewPrice(price)
    newPriceList[i].newPrice.price = ""
//    console.log(newPriceList[i])
    this.setState({productPrices: newPriceList})
  }
  }

  handleChange = (i, e) => {
    const newPrice = this.state.productPrices
//    console.log('i: ', i);
//    console.log('e: ', e);
//    console.log('newPrice: ', newPrice);
    newPrice[i].newPrice[e.target.id] = e.target.value
    this.setState({productPrices: newPrice})
  };

  addItemPriceToList (){
    const newPrice = this.state.itemPrices
    const price = {
      ...newPrice.newPrice, 
      itemId: this.props.itemId,
    }
    newPrice.prices.splice(0,0,price);
    this.props.createNewPrice(price)
    this.setState({productPrices: newPrice})
  }

  handleChangeItem = (e) => {
    const newPrice = this.state.itemPrices
    newPrice.newPrice[e.target.id] = e.target.value
    this.setState({itemPrices: newPrice})
  };

  deleteProductFromItem(item){
    const prod = this.props.inventoryItem.productIds.filter((id) => id !== item.id)
    const newItem = {...this.props.inventoryItem, productIds: prod}
    this.props.updateItem(this.props.itemId, newItem)
    this.setState({productPrices: this.state.productPrices.filter((itm) => itm.product.id !== item.id)})
  }

  render() {
    const id = this.props.match.params.id;
    const {
      inventoryItem,
      auth,
      categories,
      prices,
      suppliers,
      products,
    } = this.props;
    const { items, category, productPrices, itemPrices } = this.state;

    if (!auth.uid) return <Redirect to="/signin" />;

    if (items) {
      return (
        <div>
          <Navbar />
          <Sidebar />
          <div className="main-panel">
            <div className="content mb-4">
              <div className="page-inner">
                <div className="page-header">
                  <h4 className="page-title">Item Details</h4>
                </div>

                <EditableDetails
                  items={items}
                  changeValue={this.changeValue.bind(this)}
                  action={this.updateItem.bind(this)}
                  edit={this.state.edit}
                  renderExtra={() => (
                    <div>
                      {this.state.edit ? (
                        <div className="input-field row">
                          <div className="col s4 offset-s1">
                            <a
                              className="dropdown-trigger btn"
                              onClick={() =>
                                this.setState({ showCategories: true })
                              }>
                              Select Category
                            </a>

                            <select
                              className="form-control category-select mr-2"
                              onChange={(event) =>
                                this.setState({
                                  category: event.target.value,
                                  showCategories: false,
                                })
                              }>
                              {categories?.map(
                                (category, i) =>
                                  category && (
                                    <option value={category}>{category}</option>
                                  ),
                              )}
                            </select>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="detail-title">Category:</div>
                          <div className="detail-body">
                            {category.charAt(0).toUpperCase() +
                              category.slice(1)}
                          </div>
                        </div>
                      )}
                      <div className="col s12" style={{ textAlign: 'right' }}>
                        Created:{' '}
                        {moment(inventoryItem.dateCreated.toDate()).calendar()}
                      </div>
                    </div>
                  )}
                />

                <h1>Price history1</h1>

                {/* Product Price history card */}  
                {productPrices &&
                  productPrices.length > 0 &&
                  productPrices.map((outerItem, i) => {

                    return (
                      <div key={i} className="card z-depth-0">
                        <div className="card-content">
                          <div className="page-header justify-content-between page-header1">
                            <div className="card-title">
                              <span className="">
                                UPC: {outerItem.product.upc}{' '}
                                &nbsp;&nbsp;&nbsp;
                                {outerItem.product.description}
                              </span>
                            </div>

                            <div
                              className="justify-content-between"
                              style={{ flexDirection: 'row' }}>
                              <button
                                className="shopping-arrow border-none"
                                onClick={() =>
                                  this.deleteProductFromItem(outerItem.product)
                                }>
                                <i
                                  className="material-icons"
                                  aria-hidden="true"
                                  style={{ cursor: 'pointer' }}>
                                  delete
                                </i>
                              </button>
                            </div>
                          </div>
                          <div style={{ maxHeight: 350, overflow: 'scroll' }}>
                            <table className="responsive-tabe highlight itemtable price-table">
                              <thead>
                                <tr>
                                  <th>Price</th>
                                  <th>Supplier</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                
                                <tr>
                                  <td>
                                    <input
                                      className="short-input"
                                      id="price"
                                      type="number"
                                      step="0.01"
                                      placeholder="-"
                                      value={outerItem.newPrice.price}
                                      onChange={(e) => this.handleChange(i, e)}
                                      style={{
                                        borderWidth: 1,
                                      }}
                                    />
                                  </td>
                                  <td>
                                    {suppliers && (
                                      <select
                                        className="form-control price-table1"
                                        id="supplierId"
                                        onChange={(e) =>
                                          this.handleChange(i, e)
                                        }>
                                        <option value={"1"}>
                                          Select Supplier
                                        </option>
                                        {suppliers?.map(
                                          (supplier, i) =>
                                            supplier && (
                                              <option
                                                key={supplier.id}
                                                value={supplier.id}>
                                                {supplier.nickName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  supplier.nickName.slice(1)}
                                              </option>
                                            ),
                                        )}
                                      </select>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => this.addPriceToList(i)}
                                      className="blue-btn">
                                      {'Add'}
                                    </button>
                                    {/* {moment(
                                          new Date,
                                        ).calendar()} */}
                                  </td>
                                </tr>

                                {outerItem.prices &&
                                outerItem.prices.length > 0 ?
                                  outerItem.prices.map((price) => {
                                    return (
                                      <tr key={price.id}>
                                        <td>
                                          <input
                                            className="short-input"
                                            value={price.price}
                                            readOnly={true}
                                            style={{
                                              ...(this.state.edit && {
                                                borderWidth: 1,
                                              }),
                                            }}
                                          />
                                        </td>
                                        {suppliers ? (
                                          suppliers
                                            .filter(
                                              (supplier) =>
                                                supplier.id == price.supplierId,
                                            )
                                            .map((supplier) => (
                                              <td key={supplier.id}>
                                                {supplier.nickName}
                                              </td>
                                            ))
                                        ) : (
                                          <td>location</td>
                                        )}
                                        <td>
                                          {moment(
                                            (price.dateCreated &&
                                              price.dateCreated.toDate()) ||
                                              new Date(),
                                          ).calendar()}
                                        </td>
                                      </tr>
                                    );
                                  })
                                  :
                                  <tr key={i} >
                                    <td></td>
                                    <td>
                                      <span style={{padding: 20}}>
                                         No item to show
                                      </span>
                                    </td>
                                    <td></td>
                                  </tr>
                        }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* Item Price history card */}
                {itemPrices &&
                  itemPrices.prices &&
                  itemPrices.prices.length >= 0 && (
                    <div className="card z-depth-0">
                      <div className="card-content">
                        <span className="card-title">
                          Cafe Item: {itemPrices.item.name} &nbsp;&nbsp;&nbsp;
                          {itemPrices.item.description}
                        </span>
                        <table className="responsive-tabe highlight itemtable price-table">
                          <thead>
                            <tr>
                              <th>Price</th>
                              <th>Supplier</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  className="short-input"
                                  id="price"
                                  type="number"
                                  step="0.01"
                                  placeholder="-"
                                  value={itemPrices.newPrice.price}
                                  onChange={(e) => this.handleChangeItem(e)}
                                  style={{
                                    borderWidth: 1,
                                  }}
                                />
                              </td>
                              <td>
                                {suppliers && (
                                  <select
                                    className="form-control"
                                    style={{ marginLeft: 30 }}
                                    id="supplierId"
                                    onChange={(e) => this.handleChangeItem(e)}>
                                    <option>Select category from a list</option>
                                    {suppliers?.map(
                                      (supplier, i) =>
                                        supplier && (
                                          <option
                                            key={supplier.id}
                                            value={supplier.id}>
                                            {supplier.nickName
                                              .charAt(0)
                                              .toUpperCase() +
                                              supplier.nickName.slice(1)}
                                          </option>
                                        ),
                                    )}
                                  </select>
                                )}
                              </td>
                              <td>
                                <button
                                  onClick={() => this.addItemPriceToList()}
                                  className="blue-btn">
                                  {'Add'}
                                </button>
                                {/* {moment(
                                          new Date,
                                        ).calendar()} */}
                              </td>
                            </tr>

                            {itemPrices.prices &&
                              itemPrices.prices.map((price) => {
                                return (
                                  <tr key={price.id}>
                                    <td>
                                      <input
                                        className="short-input"
                                        value={price.price}
                                        readOnly={true}
                                        style={{
                                          ...(this.state.edit && {
                                            borderWidth: 1,
                                          }),
                                        }}
                                      />
                                    </td>
                                    {suppliers ? (
                                      suppliers
                                        .filter(
                                          (supplier) =>
                                            supplier.id == price.supplierId,
                                        )
                                        .map((supplier) => (
                                          <td key={supplier.id}>
                                            {supplier.nickName}
                                          </td>
                                        ))
                                    ) : (
                                      <td>location</td>
                                    )}
                                    <td>
                                      {moment(
                                        (price.dateCreated &&
                                          price.dateCreated.toDate()) ||
                                          new Date(),
                                      ).calendar()}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                

                <div className="card z-depth-0">
                  <div className="card-content">
                    <div className="page-header justify-content-between page-header1">
                      <div className="card-title">
                        <span className="">Assosiate a UPC to this item</span>
                      </div>

                      <div
                        className="justify-content-between"
                        style={{ flexDirection: 'row' }}>
                        <button
                        data-toggle="modal"
                        data-target="#addNewUPCmodal" 
                        className="blue-btn">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddUPCDialog 
             products={products} 
             addedProducts={this.props.inventoryItem.productIds} 
             addProductToList={this.addProductToList}
             hideBtns
             />
        </div>
      );
    } else {
      return (
        <div className="container center">
          <p>Loading item...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  // Todo: We should get just the specific item from firestore instead of all of the items and then filtering it out here
  const id = ownProps.match.params.id;

  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    inventoryItem: state.firestore.data.inventoryItem,
    prices: state.firestore.ordered.prices,
    products: state.firestore.ordered.products,
    suppliers: state.firestore.ordered.suppliers,
    itemId: id,
    categories: state.item.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItem: (itemId, item) => dispatch(updateItem(itemId, item)),
    createNewPrice: (newPrice) => dispatch(createNewPrice(newPrice)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }
    return [
      {
        collection: 'cafes',
        doc: props.profile.defaultCafeId,
        subcollections: [
          { collection: 'inventoryItems', doc: ownProps.match.params.id },
        ],
        storeAs: 'inventoryItem',
      },
      {
        collection: 'products',
        orderBy: ['description'],
      },
      {
        collection: 'prices',
        orderBy: ['dateCreated', 'desc'],
        storeAs: 'prices',
      },
      {
        collection: 'suppliers',
        storeAs: 'suppliers',
      },
    ];
  }),
)(ItemDetails);