import React, { Component } from 'react';
import { connect } from 'react-redux';
import createProduct from '../../store/actions/productActions';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import Navbar from '../layout/Navbar';
import Sidebar from '../layout/Sidebar';
class CreateProduct extends Component {
  state = {
    description: '',
    brand: '',
    upc: '',
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    console.log(this.state);
    e.preventDefault();
    this.props.createProduct(
      this.state,
    );
    this.props.history.push('/products');
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/products" />;

    return (
      <div>
        <Navbar />
        <Sidebar />
        <div className="main-panel">
          <div className="content">
            <div className="page-inner">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="page-header">
                      <h4 className="page-title">Add New Product</h4>
                    </div>

                    <div className="container">
                      <form
                        onSubmit={this.handleSubmit}
                        className="white"
                        style={{ margin: '0px', padding: '0px' }}>
                        <div className="card-body pb-0 create-item-block d-sm-block">
                          <div className="row" style={{ padding: '0px' }}>
                            <div className="form-group col-md-12">
                              <label htmlFor="description">Description</label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                id="description"
                                onChange={this.handleChange}
                                placeholder="Product description"
                              />
                            </div>
                            <div className="form-group col-md-5">
                              <label htmlFor="upc">UPC</label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                id="upc"
                                placeholder="12 digit UPC"
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group col-md-7">
                              <label htmlFor="brand">Manufacture</label>
                              <input
                                type="text"
                                className="form-control"
                                id="brand"
                                placeholder="Manufacture/Brand"
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <label htmlFor="size">Size/Weight</label>
                              <input
                                type="number"
                                step="0.1" 
                                className="form-control"
                                id="size"
                                placeholder="123"
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="upc">Unit</label>
                              <input
                                type="text"
                                className="form-control"
                                id="unit"
                                placeholder="oz, lbs, count..."
                                onChange={this.handleChange}
                              />
                            </div>
                            
                            
                            
                            <div className="form-group col-md-12">
                              <div className="input-field">
                                <button className="blue-btn">Create</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (product) =>
      dispatch(createProduct(product)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CreateProduct);

