const initState = {};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_PRODUCT_SUCCESS":
      console.log("create product success");
      return { ...state };

    case "CREATE_PRODUCT_ERROR":
      console.log("create product error");
      return state;

    case "CREATE_PRICE_SUCCESS":
      console.log("create price success");
      return { ...state };

    case "CREATE_PRICE_ERROR":
      console.log("create price error");
      return state;

    case "UPDATE_MIN_PRICE":
      return {...state, productUPC: action.productUPC};

    default:
      return state;
  }
};

export default productReducer;
