import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { conductInventory, updateInventoryList } from "../../store/actions/itemActions";
import { getStatusColorClass, getNextStatus } from "../utils/HelperFunctions";
import Navbar from '../layout/Navbar';
import Sidebar from '../layout/Sidebar';
import ItemDetailDialog from './ItemDetailDialog';
class InventoryListDetails extends React.Component {
  constructor(props) {
    super(props);

    this.button = React.createRef();
  }
  state = {
    fetched: false,
  };

  componentDidUpdate() {
    const { inventoryList } = this.props;
    if (
      (!this.state.fetched && inventoryList) ||
      this.state.inventoryList?.items?.length !== inventoryList?.items?.length
    ) {
      this.setState({
        fetched: true,
        inventoryList,
      });
    }
  }

  editNote(note) {
    if (note.length < 500) this.setState({ note });
  }

  onItemClick(item){
    this.setState({item: item})
    this.button.current.click()
  }

  updateItemStatus = (status, id) => {
    const { inventoryList } = this.state;
    const newStatus = getNextStatus(status)
    const newList = inventoryList.items.map((elem) => {
    return {
      ...elem,
      ...id === elem.id && {status: newStatus}
    }});
    this.setState({inventoryList: {...inventoryList, items: newList}})
    // this.props.updateInventoryList(this.props.match.params.id, {items: newList});
  }

  changeStatus(key, status) {
    const inventoryList = JSON.parse(JSON.stringify(this.state.inventoryList));
    inventoryList.items[key].status = status;
    this.setState({ inventoryList });
  }

  changeStock(key, stock) {
    const inventoryList = JSON.parse(JSON.stringify(this.state.inventoryList));
    const { par } = inventoryList.items[key];
    inventoryList.items[key].stock = stock;

    const fStock = parseFloat(stock);
    const fPar = parseFloat(par);

    // if greather than or equal to par, return stock level "ok"
    // if less than or equal to 20% of par, return "out"
    // else return "low"
    inventoryList.items[key].status =
      fStock >= fPar
        ? "ok"
        : fStock <= (.2 * fPar)
        ? "out"
        : "low";

    this.setState({ inventoryList });
  }

  disableSubmit() {
    return !this.state.inventoryList.items.every((item) => item.status);
    // verify that every item has been touch otherwise we disable the submit button.
    // This logic has been temporarily removed since we do not indicate which items have
    // not been touched.  This will frustrate users when dealing with a long inventory list.
    // Add the following code back into the submit button to re-enable logic
    // disabled={this.disableSubmit()}
  }

  submit() {
    this.props.conductInventory(this.state.inventoryList, this.state.note);
    this.props.history.push("/inventoryLists");
  }

  render() {
    const { auth, isView } = this.props;
    const { inventoryList } = this.state;
    if (!auth.uid) return <Redirect to="/signin" />;
    if (inventoryList) {
      return (
        <div>
          <Navbar />
          <Sidebar />
          <div className="main-panel">
            <div className="content mb-4">
              <div className="page-inner">
                <div className="page-header">
                  {isView ? (
                      <h4 className="page-title">
                        Inventory Log: {inventoryList.name}
                        <br/>
                        Date: {moment(inventoryList.dateCreated.toDate()).calendar()}
                        </h4>
                      ):(
                        <h4 className="page-title">
                        Conducting Inventory:
                        <br /> {inventoryList.name}
                        </h4>)
                    }
                  <div className="btn-group btn-group-page-header ml-auto d-none d-sm-block">
                    {!isView ? (
                      <div className="col-md-12 d-flex justify-content-end">
                        <button
                          type="button"
                          onClick={() =>
                            this.props.history.push('/inventoryLists')
                          }
                          className="outline-blue-btn long-btn mr-3">
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="blue-btn"
                          onClick={() => this.submit()}
                          style={{ fontSize: 14 }}>
                          Submit inventory
                        </button>
                      </div>
                    ) : (
                      <div className="col-md-12 d-flex justify-content-end">
                        <button
                          type="button"
                          onClick={() => this.props.history.push('/cafe')}
                          className="outline-blue-btn long-btn mr-3">
                          Close
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">Inventory items</div>
                      </div>

                      {/* Begin Mobile view */}
                      <div className="card-body pb-0">
                        <ul className="mobile-view d-md-down-none d-block d-sm-none">
                          {inventoryList?.items?.map((item, i) => (
                            <li key={i}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="item w-100">
                                  <p className="title">
                                    <a   
                                    style={{ marginLeft: 5, cursor: 'pointer'  }}
                                    className="border-none"
                                    onClick={()=> this.onItemClick(item)}>
                                      {item.name}
                                    </a>
                                  </p>
                                  <p className="content d-flex justify-content-between">
                                    <span>par: {item.par}</span>
                                    <span>
                                      <input
                                        disabled={item.inStock}
                                        onChange={(e) =>
                                          this.changeStock(i, e.target.value)
                                        }
                                        readOnly={isView}
                                        placeholder="-"
                                        type="text"
                                        className="short-input"
                                        value={item.stock}
                                      />
                                    </span>
                                    <span>
                                      {item && (
                                        <button
                                          onClick={() =>
                                            this.updateItemStatus(
                                              item.status,
                                              item.id,
                                            )
                                          }
                                          className={getStatusColorClass(
                                            item.status,
                                          )}>
                                          {item.status || 'OK'}
                                        </button>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Begin Desktop view */}
                      <div className="card-body pb-0 d-none d-sm-block">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Item name</th>
                              <th>Par</th>
                              <th>Stock on Hand</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inventoryList?.items?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <a
                                    style={{ marginLeft: 5, cursor: 'pointer'  }}
                                    className="border-none"
                                    onClick={()=> this.onItemClick(item)}
                                    >                                   
                                    {item.name}
                                  </a>
                                </td>
                                <td>{item.par}</td>
                                <td>
                                  <input
                                    disabled={item.inStock}
                                    onChange={(e) =>
                                      this.changeStock(i, e.target.value)
                                    }
                                    readOnly={isView}
                                    placeholder="--"
                                    type="text"
                                    className="short-input"
                                    value={item.stock}
                                  />
                                </td>
                                <td>
                                  {item && (
                                    <button
                                      onClick={() =>
                                        this.updateItemStatus(
                                          item.status,
                                          item.id,
                                        )
                                      }
                                      className={getStatusColorClass(
                                        item.status,
                                      )}>
                                      {item.status || 'OK'}
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">Inventory notes:</div>
                      </div>
                      <div className="card-body pb-0">
                        <textarea
                          onChange={(e) => this.editNote(e.target.value)}
                          className="form-control"
                          rows="7"
                          value={inventoryList.note}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-md-down-none d-block d-sm-none fix-close-btn">
                    <div className="col-md-12 d-flex justify-content-center">
                      <button
                        type="button"
                        onClick={() =>
                          this.props.history.push('/inventoryLists')
                        }
                        className="outline-blue-btn long-btn mr-3">
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="blue-btn"
                        onClick={() => this.submit()}
                        style={{ fontSize: 14 }}>
                        Submit inventory
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a className="d-none"
               data-toggle="modal"
               ref={this.button}
               data-target="#itemDetailModal" />
            <ItemDetailDialog item={this.state.item} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="container center">
          <p>Loading item...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const index = ownProps.match.params.index ? ownProps.match.params.index : "0";
                                
  const inventoryLists = state.firestore.data.inventoryLists;
  const inventoryList = index === "1" ? 
                        state.firestore.data.inventories ?
                                state.firestore.data.inventories[id] : 
                                null  : 
                        inventoryLists ? inventoryLists[id] : null;
  const inventoryItems = state.firestore.ordered.inventoryItems;

  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    inventoryList: inventoryList,
    inventoryItems: inventoryItems,
    isView: index === "1"
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    conductInventory: (inventory, note) =>
      dispatch(conductInventory(inventory, note)),
      updateInventoryList: (listId, item) =>
      dispatch(updateInventoryList(listId, item)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }

    return [
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [
          {
            collection: "inventoryList",
          },
        ],
        storeAs: "inventoryLists",
      },
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "inventoryItems" }],
        storeAs: "inventoryItems",
      },
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "inventories" }],
        storeAs: "inventories",
      },
    ];
  })
)(InventoryListDetails);
