import React, { Component } from "react";
import { connect } from "react-redux";
import createSupplier from "../../store/actions/supplierActions";
import { Redirect, NavLink } from "react-router-dom";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";

class CreateSupplier extends Component {
  state = {
    name: "",
    address: "",
    city: "",
    state: "",
    phoneNumber: "",
    contact: "",
    email: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createSupplier(this.state);
    this.props.history.push("/suppliers");
  };

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/products" />;

    return (
      <div>
        <Navbar />
        <Sidebar />

        <div className="main-panel">
          <div className="content">
            <div className="page-inner">
               
              <div className="row">
                <div className="col-md-12">
                  <div className="card">

                    <div className="card-header d-sm-block">
                      <div className="d-flex justify-content-between flex-wrap">
                        <h4 className="card-title mb-2">Create New Supplier</h4>
                      </div>
                    </div>

                    {/* Begin Form */}
                    <div className="container">
                      <form onSubmit={this.handleSubmit} className="white" style={{margin: '0px', padding: '0px'}}>
                        <div className="card-body pb-0 create-item-block d-sm-block">
                          <div className="row" style={{padding: '0px'}}>

                            <div className="form-group col-md-12">
                              <label htmlFor="name">Business Name</label>
                              <input 
                                required
                                type="text" 
                                className="form-control" 
                                id="name" 
                                onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-12">
                              <label htmlFor="createProDesc">Business Nick Name</label>
                              <input 
                                type="text" 
                                className="form-control" 
                                id="nickName" 
                                onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-12">
                              <label htmlFor="createProDesc">Description</label>
                              <input 
                                type="text" 
                                className="form-control" 
                                id="description" 
                                onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-12">
                              <label htmlFor="createProDesc">Address</label>
                              <input 
                                type="text" 
                                className="form-control" 
                                id="address" 
                                onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-5">
                              <label htmlFor="createProDesc">City</label>
                              <input 
                                type="text" 
                                className="form-control" 
                                id="city" 
                                onChange={this.handleChange} />
                            </div>
                            <div className="form-group col-md-5">
                              <label htmlFor="createProDesc">State</label>
                              <input 
                                type="text" 
                                className="form-control" 
                                id="state" 
                                onChange={this.handleChange} />
                            </div>
                            <div className="form-group col-md-2">
                              <label htmlFor="createProDesc">Zip</label>
                              <input 
                                type="text" 
                                className="form-control" 
                                pattern="[0-9]*"
                                id="zip" 
                                onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-12">
                              <label htmlFor="createProDesc">Contact Name</label>
                              <input 
                                type="tetextl" 
                                className="form-control" 
                                id="contact" 
                                onChange={this.handleChange} />
                            </div>
                            
                            <div className="form-group col-md-6">
                              <label htmlFor="createProDesc">Email</label>
                              <input 
                                type="email" 
                                className="form-control" 
                                id="email" 
                                onChange={this.handleChange} />
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="createProDesc">Phone Number (xxx-xxx-xxxx)</label>
                              <input 
                                type="tel" 
                                className="form-control" 
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                id="phoneNumber" 
                                onChange={this.handleChange} />
                            </div>

                            <div className="form-group col-md-12">
                              <div className="input-field">
                                <button className="blue-btn">Create</button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSupplier: (supplier) => dispatch(createSupplier(supplier)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSupplier);
