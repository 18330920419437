import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import EditableDetails from "../utils/EditableDetails";
import { updateProduct } from "../../store/actions/productActions";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";

class ProductDetails extends React.Component {
  state = {
    edit: false,
    set: false,
    show: false,
  };

  componentDidUpdate() {
    const { product } = this.props;
    if (!this.state.set && product && product.description) {
      this.setState({
        set: true,
        items: {
          description: {
            label: "Description",
            value: this.props.product?.description,
          },
          brand: {
            label: "Manufacture",
            value: this.props.product?.brand,
          },
          upc: {
            label: "UPC",
            value: this.props.product?.upc,
          },
          size: {
            label: "Size",
            value: this.props.product?.size,
          },
          unit: {
            label: "unit",
            value: this.props.product?.unit,
          },
        },
      });
    }
  }

  changeValue(key, value) {
    const { items } = this.state;
    items[key].value = value;

    this.setState({ items });
  }

  updateProduct() {
    const { productId, product } = this.props;
    const { stateSupplier } = this.state;

    console.log('product: ', product);
    console.log('stateSupplier: ', stateSupplier);

    let item = {};
    Object.entries(this.state.items).forEach(([key, { value }]) => {
      item = { ...item, [key]: value };
    });

    if (this.state.edit) this.props.updateProduct(productId, item, stateSupplier);
    
    this.setState((prev) => ({ edit: !prev.edit }));
  }
  render() {
    const { product, auth, prices, suppliers } = this.props;
    const { items } = this.state;

//    console.log('items: ', items);
//    console.log('product: ', product);
//    console.log('prices: ', prices);

    if (!auth.uid) return <Redirect to="/signin" />;

    if (items) {
      return (
        <div>
          <Navbar />
          <Sidebar />

          <div className="main-panel">
            <div className="content">
              <div className="page-inner">
                <div className="page-header">
                  <h4 className="page-title">Products Details</h4>
                </div>



                          
            <EditableDetails
              items={items}
              changeValue={this.changeValue.bind(this)}
              action={this.updateProduct.bind(this)}
              edit={this.state.edit}
              renderExtra={() => (
                <div>
                  <div className="col s12" style={{textAlign: 'right'}}>
                    Created: {moment(product.dateCreated.toDate()).calendar()}
                  </div>
                </div>
              )}
            />

            {/* Display the price history*/}
            <div className="card z-depth-0">
              <div className="card-content">
                <span className="card-title">Price history</span>
                <div style={{ maxHeight: 250, overflow: 'scroll' }}>
                  <table className="responsive-tabe highlight itemtable">
                    <thead>
                      <tr>
                        <th>Price</th>
                        <th>Supplier</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prices &&
                      prices.map((price) => {
                        return (
                          <tr key={price.id}>
                            <td>{price.price}</td>
                            {
                              suppliers ?
                                suppliers.filter(supplier => supplier.id === price.supplierId).map(supplier => 
                                  (<td key={supplier.id}>{supplier.nickName}</td>))
                                : (<td>location</td>)
                            }
                            <td>{moment(price.dateCreated.toDate()).calendar()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        </div>
        </div>


      );
                
    } else {
      return (
        <div className="container center">
          <p>Loading product...</p>
        </div>
      );
    }
  }
}





const mapDispatchToProps = (dispatch) => {
  //console.log('productd: ', product);
  //console.log('productIdd: ', productId);

  return {
    updateProduct: (productId, product ) =>
      dispatch(updateProduct(productId, product)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    productId: ownProps.match.params.id,
    product: state.firestore.data.product,
    prices: state.firestore.ordered.prices, 
    suppliers: state.firestore.ordered.suppliers,
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => { 
    return [
      {
        collection: "products",
        doc: ownProps.match.params.id,
        storeAs: "product", 
      }, 
      {
        collection: "prices",
        where: [['productId', '==', ownProps.match.params.id]], 
        orderBy: ['dateCreated', 'desc'],
        storeAs: "prices",
      },
      {
        collection: "suppliers",
        storeAs: "suppliers",
      }
    ];
  })
)(ProductDetails);
