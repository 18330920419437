import React, { Component } from "react";
import { connect } from "react-redux";
import createCafe from "../../store/actions/cafeActions";
import { Redirect } from "react-router-dom";
import SimpleNav from "../layout/SimpleNav";
import '../../assets/css/simple.css';

class CreateCafe extends Component {
  state = {
    name: "",
    address: "",
    city: "",
    state: "",
    phoneNumber: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state);
    this.props.createCafe(this.state);
    this.props.history.push("/cafe");
  };

  render() {
    const { auth } = this.props;
    //    console.log(this.props);
    if (!auth.uid) return <Redirect to="/cafe" />;

    return (
      <div>
        <SimpleNav />

<div className="container section bk-title-card" style={{marginTop: '20px'}}>
<div className="card z-depth-0">

                
        <div className="form-light">
          <div className="form-light-header">
            <h5>Create New Cafe</h5>
          </div>

          <div className="form-light-body">
            <section id="createCafe">
              <form onSubmit={this.handleSubmit} className="form-fields">
                
                <div className="form-row">
                  <label htmlFor="name"><p>Cafe Name</p></label>
                  <input
                    type="text"
                    id="name"
                    className="validate inputlong"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="form-row">
                  <label htmlFor="address"><p>Address</p></label>
                  <input type="text" id="address" className="form-input inputlong" onChange={this.handleChange} />
                </div>

                <div className="form-row">
                  <div className="form-row">
                    <label htmlFor="city"><p>City</p></label>
                    <input
                      type="text"
                      id="city"
                      className="validate form-input inputthird"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="input-field form-row">
                    <label htmlFor="state"><p>State</p></label>
                    <input
                      type="text"
                      id="state"
                      className="validate form-input inputthird"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="input-field form-row">
                    <label htmlFor="zip"><p>Zip</p></label>
                    <input type="text" id="zip" className="form-input inputthird" onChange={this.handleChange} />
                  </div>
                </div>

                <div className="input-field form-row">
                  <label htmlFor="phone"><p>Phone Number (xxx-xxx-xxxx)</p></label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    className="form-input inputmed"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="loginBtn d-flex">
                  <button className="myButton bondiBlueBg d-none d-sm-block ccbutton">Create</button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCafe: (cafe) => dispatch(createCafe(cafe)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCafe);
