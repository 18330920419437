import React, { Component } from "react";
import ProductSummary from "./ProductSummary";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";

class ProductList extends Component {
  state = {
    searchText: '',
    itemsFrom: '0',
  };


  searchTextChange = (event) => {
    this.setState({ searchText: event.target.value });
  };
  handleChange = (event) => {
    this.setState({ itemsFrom: event.target.value });
  };

  render() {
    const { products, auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    if (products) {
    return (
      <div>
          <Navbar />
          <Sidebar />
        
          <div className="main-panel">
            <div className="content">
              <div className="page-inner">
                <div className="page-header">
                  <h4 className="page-title">Products/UPC</h4>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">

                      {/* Mobile header */}
                      <div className="card-header d-md-down-none d-block d-sm-none">
                        <div className="d-flex justify-content-start flex-column">
                            <div>
                              <NavLink to="/createProduct">
                                <button className="blue-btn">
                                  Add New Product
                                </button>
                              </NavLink>
                            </div>
                          </div>
                        </div>

                        {/* Desktop header */}
                        <div className="card-header mb-3 d-none d-sm-block">
									        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <div className="d-flex">
                              {this.state.itemsFrom === '0' && (
                                <div className="search-box">
                                  <div className="d-flex">
                                    <input className="form-control height-inherit" placeholder="Search..." value={this.state.searchText} 
                                      onChange={this.searchTextChange.bind(this)}/>
                                    {/* <button className="blue-btn" style={{width: 200, marginLeft: 10 }}>Add item</button> */}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <NavLink to="/createProduct">
                            <button className="blue-btn">
                              Add New Product
                            </button>
                          </NavLink>
                        </div>
								      </div>

                    {/* Begin Mobile view */}
                    <div className="card-body pb-0 d-md-down-none d-block d-sm-none">
                      <ul className="mobile-view">
                
                        {this.state.itemsFrom === '0' && (
                          <div className="search-box">
                            <div className="d-flex">
                              <input className="form-control height-inherit" placeholder="Search..." value={this.state.searchText} 
                                onChange={this.searchTextChange.bind(this)}/>
                            </div>
                          </div>
                        )}
                        {products && products.map((product,key) => {
                          let found = true;
                          if(this.state.itemsFrom === '0' && this.state.searchText !== ''){
                            found = product.description.toLowerCase().includes(this.state.searchText.toLowerCase());
                          }
                          if(found)
                            return (
                              <li key={key}>
                                <Link to={"/product/" + product.id} key={product.id}>
                                  {product.description}
                                </Link>
                                <p className="content d-flex" style={{color: "#6c757d", fontSize: '16px'}}>UPC: {product.upc} </p>
                              </li>
                            );
                        })}
                      </ul>
                    </div>

                    {/* Begin Desktop view */}
                    <div className="card-body pb-0 d-none d-sm-block">
                      <table className="table shopping-history">
                       <thead>
									  		<tr>
										  		<th>Product</th>
											  	<th style={{width: '180px'}}>UPC</th>
											  </tr>
										  </thead>
                      <tbody>
                        {products && products.map((product) => {
                          let found = true;
                          if(this.state.itemsFrom === '0' && this.state.searchText !== ''){
                            found = product.description.toLowerCase().includes(this.state.searchText.toLowerCase());
                          }
                          if(found)
                            return (
                              <tr key={product.id}>
                                <td>
                                  <Link to={"/product/" + product.id} key={product.id}>
                                    {product.description}
                                  </Link>
                                </td>
                                <td>{product.upc}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        

        </div>
        {/* Begin Add Product modal*/}
        <div className="modal fade" id="addProduct" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add New Product</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <div className="row" style={{padding: '0px 5px'}}>
                  <div className="form-group col-md-12">
                    <label>Product name</label>
                    <input type="text" className="form-control" placeholder="List name sample" />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Par amount</label>
                    <input type="text" className="form-control" placeholder="0" />
                  </div>
                  <div className="form-group col-md-6">
                                    <label>Assign to category</label>
                                    <select className="form-control">
                                        <option>Select category from a list</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div> 
                  <div className="form-group col-md-12">
                    <label htmfor="createProDesc">Product notes</label>
                    <textarea className="form-control" rows="3" style={{resize: 'none'}} placeholder="Some text"></textarea>
                  </div>
                                <a href="#" className="add-upc-pro">Add/UPC Product</a>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="close-btn" data-dismiss="modal">Cancel</button>
                <button type="button" className="blue-btn">Add Product</button>
              </div>
            </div>
          </div>
        </div>
        {/* End Add Product modal */}

        </div>
      );
    } else {
      return (
        <div className="container center">
          <p>Loading cafes...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    products: state.firestore.ordered.products,
    auth: state.firebase.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ 
    collection: "products",
    orderBy: ['dateCreated', 'desc'],
  }])
)(ProductList);
