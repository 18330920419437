import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, NavLink } from "react-router-dom";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";

class SupplierList extends Component {
  render() {
    const { suppliers, auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div>
        <Navbar />
        <Sidebar />
      
        <div className="main-panel">
          <div className="content">
            <div className="page-inner">
              
              <div className="page-header">
                <h4 className="page-title">Suppliers</h4>
              </div>
        

              <div className="row">

                {/* Begin Header */}
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header mb-3">
                      <div className="d-flex justify-content-between flex-wrap">
                        <h4 className="card-title mb-2">Your cafe items</h4>
                        <div>
                          <NavLink to="/addSupplier">
                            <button className="blue-btn">
                              Add New Supplier
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    {/* Begin Mobile view */}
                    <div className="card-body pb-0 d-md-down-none d-block d-sm-none">
                      <ul className="mobile-view">
                        {suppliers && suppliers.map((supplier) => {
                          return (
                            <li>
                              <Link to={"/supplier/" + supplier.id} key={supplier.id}>
                                {supplier.nickName}
                              </Link>
                              <p>{supplier.name} </p>
                              <p>{supplier.city} {supplier.zip}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* End Mobile view */}
                
                    {/* Begin Desktop view */}
                    <div className="card-body pb-0 shopping-list-block d-none d-sm-block">
                      <table className="table shopping-list">
                        <thead>
                          <tr>
                            <th style={{width: '30%'}}>Supplier name</th>
                            <th>Nickname</th>
                            <th style={{width: '20%'}}>City</th>
                          </tr>
                        </thead>
                        <tbody>
                          {suppliers && suppliers.map((supplier) => {
                            return (
                              <tr>
                                <td data-toggle="modal">
                                  <Link to={"/supplier/" + supplier.id} key={supplier.id}>
                                    {supplier.name}
                                  </Link>
                                </td>
                                <td>{supplier.nickName} </td>
                                <td>{supplier.city} {supplier.zip} &nbsp;</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* End Desktop view */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    suppliers: state.firestore.ordered.suppliers,
    auth: state.firebase.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "suppliers", orderByValue: ("name", "dsc") }])
)(SupplierList);
