import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect, NavLink } from 'react-router-dom';
import M from 'materialize-css';
import AddItemDialog from './AddItemDialog';
import HistoryItem from './HistoryItem';
import moment from 'moment';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import Navbar from '../layout/Navbar';
import Sidebar from '../layout/Sidebar';
import { addItemToList, itemStatusChange } from '../../store/actions/shoppingActions';
import { getStatusColorClass, getNextStatus } from '../utils/HelperFunctions';
class ShoppingList extends Component {
  constructor(props) {
    super(props);

    this.button = React.createRef();
  }

  state = {
    historyItems: [],
  };

  componentDidMount() {
    this.container.addEventListener('DOMContentLoaded', this.handler());
  }

  componentWillUnmount() {
    this.container.removeEventListener('DOMContentLoaded', this.handler());
  }

  handler = () => {
    const elems = this.container.querySelectorAll('.modal');
    M.Modal.init(elems, {});
  };

  updateItemStatus = (status, id) => {
    console.log(status, id);
    const newStatus = getNextStatus(status);
    const newList = this.props.shopping.list.map((elem) => {
      return {
        ...elem,
        ...(id === elem.id && { status: newStatus }),
      };
    });
    this.props.itemStatusChange(newList);
  };


  deleteItemfromList(itm) {
    const newShoppingList= this.props.shopping.list.filter((value) => (itm.itemId ? itm.itemId : itm.id) !== (value.itemId ? value.itemId : value.id))
    this.props.addItemToList(newShoppingList);
  }

  render() {
    const { auth, shopping, logs, suppliers } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div>
        <Navbar />
        <Sidebar />

        <div className="main-panel">
          <div className="content">
            <div className="page-inner">
              <div className="page-header">
                <h4 className="page-title">Shopping lists</h4>
                <div className="btn-group btn-group-page-header ml-auto">
                  <button
                    type="button"
                    style={{ width: '175px' }}
                    aria-haspopup="true"
                    data-target="#modal3"
                    data-toggle="modal"
                    className="blue-btn d-none d-sm-block modal-trigger"
                    aria-expanded="false">
                    Start shopping
                  </button>
                </div>
              </div>

              {/* Current Shopping List section */}
              <div ref={(ref) => (this.container = ref)} className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header mb-3">
                      <div className="d-flex justify-content-between flex-wrap">
                        <h4 className="card-title mb-2">
                          Current Shopping List
                        </h4>
                        <div>
                          <button
                            data-toggle="modal"
                            data-target="#addNewItemShoppingmodal"
                            className="outline-blue-btn modal-trigger">
                            Add new item
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Begin mobile view */}
                    <div className="card-body pb-0 d-md-down-none d-block d-sm-none">
                      <ul className="mobile-view">
                        {shopping?.list?.length > 0 &&
                          shopping.list.map((item, i) => (
                            <li key={i}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="item w-100">
                                  <p className="title">{item.name}</p>
                                  <p className="content d-flex">
                                    <span>{item.par}</span>
                                  </p>
                                </div>
                                {item && (
                                  <button
                                    onClick={() =>
                                      this.updateItemStatus(
                                        item.status,
                                        item.id,
                                      )
                                    }
                                    className={getStatusColorClass(
                                      item.status,
                                    )}>
                                    {item.status || 'OK'}
                                  </button>
                                )}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="card-body pb-0 shopping-list-block d-none d-sm-block">
                      <table className="table shopping-list">
                        <thead>
                          <tr>
                            <th>Item name</th>
                            <th>Par</th>
                            <th style={{ width: '20%' }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shopping?.list?.length > 0 &&
                            shopping.list.map((item, i) => (
                              <tr key={i}>
                                <td>{item.name}</td>
                                <td>{item.par}</td>
                                <td className="list-status">
                                  {item && (
                                    <button
                                      onClick={() =>
                                        this.updateItemStatus(
                                          item.status,
                                          item.id,
                                        )
                                      }
                                      className={getStatusColorClass(
                                        item.status,
                                      )}>
                                      {item.status || 'OK'}
                                    </button>
                                  )}
                                    <i
                                    onClick={()=> this.deleteItemfromList(item)}
                                     style={{ cursor: 'pointer' }}
                                      className="fa fa-chevron-right"
                                      className="material-icons">
                                            delete
                                      </i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* Shopping history section */}
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <span className="card-title">Shopping History</span>
                    </div>
                    <div className="card-body pb-0">
                      <div className="d-md-down-none d-block d-sm-none">
                        <ul className="mobile-view">
                          {logs &&
                            Object.entries(logs).map(([key, value]) => {
                              return (
                                <li key={key}>
                                  <a
                                    className="shopping-arrow"
                                    data-toggle="modal"
                                    data-target="#shoppingTrip">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="item w-100">
                                        <p className="title">
                                          {' '}
                                          {moment(
                                            value.dateCreated.toDate(),
                                          ).calendar()}
                                        </p>
                                        <p className="content d-flex">
                                          <span> {value.list.length}</span>
                                        </p>
                                      </div>
                                      <button
                                        className="shopping-arrow custom-toggle"
                                        data-toggle="modal"
                                        data-target="#shoppingHistory"
                                        onClick={() => {
                                          this.setState({
                                            ...this.state,
                                            historyItems: value.list,
                                          });
                                        }}>
                                        <i
                                          className="fa fa-chevron-right"
                                          aria-hidden="true"></i>
                                      </button>
                                    </div>
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="d-none d-sm-block">
                        <table className="table conduct-list-table">
                          <colgroup>
                            <col span="1" style={{ width: '70%' }} />
                            <col span="1" style={{ width: '30%' }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th>Date Shoppped</th>
                              <th style={{ textAlign: 'center' }}>
                                Item Count
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {logs &&
                              Object.entries(logs).map(([key, value]) => {
                                return (
                                  <tr key={key}>
                                    <td>
                                      {' '}
                                      {moment(
                                        value.dateCreated.toDate(),
                                      ).calendar()}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      {value.list.length}
                                    </td>
                                    <td>
                                      <button
                                        className="shopping-arrow"
                                        data-toggle="modal"
                                        data-target="#shoppingHistory"
                                        onClick={() => {
                                          this.setState({
                                            ...this.state,
                                            historyItems: value.list,
                                          });
                                        }}>
                                        <i
                                          className="fa fa-chevron-right"
                                          aria-hidden="true"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddItemDialog shopping={shopping} />
          <HistoryItem historyItems={this.state.historyItems} />
        </div>
        <div className="d-md-down-none d-block d-sm-none fix-close-btn">
          <button
            type="button"
            className="blue-btn long-btn modal-trigger"
            aria-haspopup="true"
            aria-expanded="false"
            data-target="#modal3"
            data-toggle="modal"
            aria-expanded="false">
            Start shopping
          </button>
        </div>

        <div id="modal3" className="modal fade" role="dialog">
          <div className="modal-dialog add-suppplier-shopping-modal">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Select Store</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-md-8">
                    <label htmlFor="exampleInputEmail1">Warehouse</label>
                    <select
                      className="form-control"
                      id="exampleInputEmail1"
                      onChange={(e) =>
                        this.setState({ selectedSupplier: e.target.value })
                      }
                      defaultValue={0}>
                      <option>Select Warehouse or Supplier </option>
                      {suppliers?.map(
                        (supplier, i) =>
                          supplier && (
                            <option key={i} value={supplier[0]}>
                              {supplier[1].nickName.charAt(0).toUpperCase() +
                                supplier[1].nickName.slice(1)}
                            </option>
                          ),
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  ref={this.button}
                  className="close-btn"
                  data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className="blue-btn"
                  disabled={
                    !this.state.selectedSupplier ||
                    this.state.selectedSupplier ===
                      'Select Warehouse or Supplier'
                  }
                  onClick={() => {
                    this.button.current.click();
                    this.props.history.push({
                      pathname: '/shopping/conduct',
                      state: { supplierId: this.state.selectedSupplier },
                    });
                  }}>
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    itemStatusChange: (list) => dispatch(itemStatusChange(list)),
    addItemToList: (list) => dispatch(addItemToList(list)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    shopping: state.firestore.data.shopping,
    logs: state.firestore.data.logs,
    suppliers:
      state.firestore.data.suppliers &&
      Object.entries(state.firestore.data.suppliers),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }
    return [
      {
        collection: 'cafes',
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: 'shoppingLists', doc: 'main' }],
        storeAs: 'shopping',
      },
      {
        collection: 'cafes',
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "shoppingLogs", orderBy: ['dateCreated', 'desc']}],
        storeAs: "logs",
      },
      {
        collection: 'suppliers',
        storeAs: 'suppliers',
        orderBy: ['nickName'],
      },
    ];
  }),
)(ShoppingList);
