export const createItem = (item) => {
  console.log('item: ', item);
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;
    const profile = getState().firebase.profile;

    delete item.show;
    // item.par = parseFloat(item.par)

    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("inventoryItems")
      .add({
        ...item,
        createdBy: authorId,
        dateCreated: new Date(),
      })
      .then(() => {
        dispatch({ type: "CREATE_ITEM_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_ITEM_ERROR" }, err);
      });
  };
};

export const createItemList = (itemList) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;
    const profile = getState().firebase.profile;

    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("inventoryList")
      .add({
        ...itemList,
        createdBy: authorId,
        dateCreated: new Date(),
      })
      .then(() => {
        dispatch({ type: "CREATE_ITEM_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_ITEM_ERROR" }, err);
      });
  };
};

export const addItem = (item) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;
    const profile = getState().firebase.profile;

    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("inventoryList")
      .add({
        ...item,
        createdBy: authorId,
        dateCreated: new Date(),
      })
      .then(() => {
        dispatch({ type: "CREATE_ITEM_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_ITEM_ERROR" }, err);
      });
  };
};

export const conductInventory = (inventory, note) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;

    delete inventory.dateUpdated;
    delete inventory.itemCount;

    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("inventories")
      .add({
        ...inventory,
        note: note || "",
        dateCreated: new Date(),
      })
      .then(() => {
        dispatch({
          type: "CONDUCT_INVENTORY_SUCCESS",
        });
      })
      .catch((err) => {
        dispatch({ type: "CONDUCT_INVENTORY_ERROR" }, err);
      });
  };
};

export const deleteInventoryList = (listId) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;

    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("inventoryList")
      .doc(listId)
      .delete()
      .then(() => {
        dispatch({
          type: "DELETE_INVENTORY_SUCCESS",
        });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_INVENTORY_ERROR" }, err);
      });
  };
};

export const deleteItemFromList = (listId, item) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const { inventoryLists } = getState()?.firestore?.data;
    const profile = getState().firebase.profile;

    const items = inventoryLists[listId]?.items.slice();

    items.splice(item, 1);

    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("inventoryList")
      .doc(listId)
      .update({
        items: [...items],
        dateUpdated: new Date(),
        itemCount: items.length,
      })
      .then(() => {
        dispatch({
          type: "DELETE_FROM_INVENTORY_SUCCESS",
        });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_FROM_INVENTORY_ERROR" }, err);
      });
  };
};

export const addItemToList = (listId, item) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const { inventoryLists } = getState()?.firestore?.data;
    const profile = getState().firebase.profile;

    const items = inventoryLists[listId]?.items;

    let exists = items?.some((element) => element.id === item.id);
    if (!exists)
      firestore
        .collection("cafes")
        .doc(profile.defaultCafeId)
        .collection("inventoryList")
        .doc(listId)
        .update({
          items: [...items, item],
          dateUpdated: new Date(),
          itemCount: items.length + 1,
        })
        .then(() => {
          dispatch({
            type: "ADD_TO_INVENTORY_SUCCESS",
          });
        })
        .catch((err) => {
          dispatch({ type: "ADD_TO_INVENTORY_ERROR" }, err);
        });
  };
};

export const updateItem = (itemId, item) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;

    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("inventoryItems")
      .doc(itemId)
      .update({
        ...item,
      })
      .then(() => {
        dispatch({
          type: "UPDATE_ITEM_SUCCESS",
        });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_ITEM_ERROR" }, err);
      });
  };
};

export const updateInventoryList = (itemId, item) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;

    firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("inventoryList")
      .doc(itemId)
      .update({
        ...item,
      })
      .then(() => {
        dispatch({
          type: "UPDATE_ITEM_SUCCESS",
        });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_ITEM_ERROR" }, err);
      });
  };
};

export const createNewPrice = (priceItem) => {
  return (dispatch, getState, { getFirebase }) => {

    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;

    firestore
    .collection("prices")
    .add({
      ...priceItem,
      createdBy: authorId,
      dateCreated: new Date(),
    })
    .then(() => {
      dispatch({ type: "UPDATE_ITEM_SUCCESS" });
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_ITEM_ERROR" }, err);
    });
  };
};


export const promote = ({ items, id }) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;

    const shoppingRef = firestore
      .collection("cafes")
      .doc(profile.defaultCafeId)
      .collection("shoppingLists")
      .doc("main");

      if (items && items.length > 0) {
        shoppingRef
          .get()
          .then((doc) => {
            let list = [];
  
            if (doc.exists) {
              const shopping = doc.data();  
              list = items.filter(item => item.status === "low" || item.status === "out");
              shopping.list.forEach((elem) => {
                const exists = list.find((item) => item.id === elem.id);
                if (!exists) list.push(elem);
              });
            } else {
              list = items.map((item) => {
                if (item.status !== "ok") return item;
              });
            }
          firestore
            .collection("cafes")
            .doc(profile.defaultCafeId)
            .collection("inventories")
            .doc(id)
            .update({ promoted: true })
            .then(() => {
              shoppingRef
                .set(
                  {
                    list: list || [],
                    dateUpdated: new Date(),
                    updatedBy: authorId,
                  },
                  { merge: true }
                )
                .then(() => {
                  dispatch({ type: "PROMOTE_SUCCESS" });
                })
                .catch((err) => {
                  dispatch({ type: "PROMOTE_ERROR", err });
                });
            })
            .catch((err) => {
              dispatch({ type: "PROMOTE_ERROR", err });
            });
        })
        .catch((err) => {
          dispatch({ type: "PROMOTE_ERROR", err });
        });
    } else {
      dispatch({ type: "PROMOTE_ERROR" });
    }
  };
};
