import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import '../../assets/css/simple.css';

const SimpleNav = (props) => {
  const { auth, profile } = props;
  
  console.log('props: ', props);
  let button;
  if (auth.uid) {
    button = <NavLink to="/signin"><button onClick={props.signOut}className="myButton bondiBlueBg d-none d-sm-block">Log out</button></NavLink>;
  } else {
    button = <NavLink to="/signin"><button className="myButton bondiBlueBg d-none d-sm-block">Log in</button></NavLink>;
  }

  return (
    <section id="header-account">
      <div className="myContainer"> 
        
        <nav className="navbar navbar-expand-lg ">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" 
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <img src={require('../../assets/img/icons/menu-icon.png')}/>
          </button>
          <a className="navbar-brand" href="/"><h1 className="mr-5">CafeCohort</h1></a>
          
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="text-right">
              <button className="navbar-toggler close-btn" type="button" data-toggle="collapse" 
                data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                ×
              </button>
            </div>

            <div className="loginBtn mt-5 d-md-down-none d-block d-sm-none">
              <NavLink to="/signin">
                <button className="myButton">Log in</button>
              </NavLink>
              <NavLink to="/signup">
                <button className="myButton bondiBlueBg" style={{marginLeft: '19px'}}>Sign up</button>
              </NavLink>
            </div>
          </div>

          <div className="loginBtn d-flex">
            {button}
            <NavLink to="/signup">
              <button className="myButton bondiBlueBg d-none d-sm-block" style={{marginLeft: '19px'}}>Sign Up</button>
            </NavLink>
          </div>

          </nav>
        </div>
            
    </section>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleNav);
