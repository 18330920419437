import React, { Component } from "react";
import { connect } from "react-redux";
import { createItem } from "../../store/actions/itemActions";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/azzara.css';
import '../../assets/css/custom.css';
import '../../assets/css/custom1.css';
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import AddUPCDialog from './AddUPCDialog'

class CreateItem extends Component {
  state = {
    name: "",
    description: "",
    par: "",
    category: "",
    show: false,
    productIds: []
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  addProductToList = (item) => {
    this.setState({
      productIds: [...this.state.productIds , item.id],
    });

  }

  handleSubmit = (e) => {
    console.log("handleSubmit");
    e && e.preventDefault();
    this.props.createItem(this.state);
    this.props.history.push("/items");
  };

  render() {
    const { auth, categories,products } = this.props;
    //   console.log(this.props);

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div>
        <Navbar />
        <Sidebar />
      
        <div className="main-panel">
          <div className="content">
            <div className="page-inner">

            <div className="row">
              <div className="col-md-12">
                <div className="card">

                  <div className="card-header d-sm-block">
                    <div className="d-flex justify-content-between flex-wrap">
                      <h4 className="card-title mb-2">Create New Item</h4>
                    </div>
                  </div>

                  <div className="container">
                    <form onSubmit={this.handleSubmit} className="white" style={{margin: '0px', padding: '0px'}}>

                      <div className="card-body pb-0 create-item-block d-sm-block">
                        <div className="row" style={{padding: '0px'}}>

                          <div className="form-group col-md-12">
                            <label htmlFor="name">Item Name</label>
                            <input 
                              required
                              type="text" 
                              className="form-control" 
                              placeholder="name..." 
                              id="name" 
                              onChange={this.handleChange} />
                          </div>

                          <div className="form-group col-md-12">
                            <label htmlFor="createProDesc">Item notes</label>
                            <textarea 
                              className="form-control" 
                              rows="3" 
                              style={{resize: 'none'}} 
                              placeholder="notes..." 
                              id="description" 
                              onChange={this.handleChange}>
                            </textarea>
                          </div>


                          <div className="form-group col-md-4">
                            <label htmlFor="par">Par amount</label>
                            <input 
                              type="text"
                              className="form-control" 
                              placeholder=".5 bag..." 
                              id="par" 
                              onChange={this.handleChange}/>
                          </div>


                          <div className="form-group col-md-4">
                            <label>Category</label>
                            <select className="form-control" id="category" onChange={this.handleChange}>
                                <option>Select category...</option>
                                {categories?.map(
                                (category, i) =>
                                  category && (
                                    <option key={i}
                                      value={category}>
                                        {category.charAt(0).toUpperCase() + category.slice(1)}
                                      </option>
                                    )
                                )}
                            </select>
                          </div> 
                          <div className="form-group col-md-12">
                          <a  data-toggle="modal"
                            data-target="#addNewUPCmodal" 
                            className="add-upc-pro">Add/Product</a>
                          </div>
                          <div className="form-group col-md-12">
                            <div className="input-field">
                              <button className="blue-btn">Create</button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <AddUPCDialog products={products} 
              addedProducts={this.state.productIds} 
              addProductToList={this.addProductToList.bind(this)} 
              onSubmit={this.handleSubmit}  
            />
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    categories: state.item.categories,
    products: state.firestore.ordered.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createItem: (item) => dispatch(createItem(item)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "products" }])
)(CreateItem);

const styles = {
  dropdown: {
    left: "inherit",
    top: "inherit",
    padding: 0,
    opacity: 1,
    display: "block",
  },
};
