import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect, NavLink } from 'react-router-dom';
import {
  updateInventoryList,
  addItemToList,
  deleteItemFromList,
  deleteInventoryList,
} from '../../store/actions/itemActions';
import Navbar from '../layout/Navbar';
import Sidebar from '../layout/Sidebar';
class InventoryListDetails extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this); 
    this.button = React.createRef();
  }

  state = {
    search: '',
    category: '',
    show: false,
    fetched: false,
    itemsFrom: '0',
    isEditState: false,
  };

  componentDidUpdate() {
    const { inventoryList } = this.props;
    if (
      (!this.state.fetched && inventoryList) ||
      this.state.inventoryList?.items?.length !== inventoryList?.items?.length
    ) {
      this.setState({
        fetched: true,
        inventoryList,
      });
    }
  }

  handleSave = (listId)=> {
    this.props.updateInventoryList(listId, {name: this.state.inventoryList.name,
      description: this.state.inventoryList.description});
      this.props.history.push('/inventoryLists')
  }

  goBack(){
      this.props.history.goBack();
  }
    
      
  handleChange = (event) => {
    this.setState({ itemsFrom: event.target.value });
  };

  searchTextChange = (event) => {
    this.setState({ search: event.target.value });
  };

  deleteInventoryList(listId) {
    this.props.deleteInventoryList(listId);
  }

  render() {
    const { search, category, inventoryList, fetched, itemsFrom } = this.state;
    const listId = this.props.match.params.id;
    const { inventoryItems, auth, categories } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;
    // console.log('props: ', props);
    if (fetched) {
      //   if (inventoryItems) {
      return (
        <div>
          <Navbar />
          <Sidebar />
          <div className="main-panel">
            <div className="content">
              <div className="page-inner">
                <div className="page-header">
                  <h4 className="page-title">Edit: {inventoryList.name}</h4>
                  <div className="btn-group btn-group-page-header ml-auto d-none d-sm-block">
                    <button
                      type="button"
                      onClick={this.goBack}
                      className="outline-blue-btn long-btn mr-3"
                      aria-haspopup="true"
                      aria-expanded="false">
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleSave.call(this, listId)}
                      className="blue-btn long-btn"
                      aria-haspopup="true"
                      aria-expanded="false">
                      Save
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-between flex-wrap">
                          <h4 className="card-title mb-2">Inventory items</h4>
                          <div>
                            <button
                              data-toggle="modal"
                              data-target="#addNewItemInventorymodal"
                              className="blue-btn modal-trigger">
                              Add new item
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pb-0">
                        <div className="d-sm-block">
                          <div className="d-sm-block">
                            {inventoryList &&
                              inventoryList?.items &&
                              inventoryList?.items?.length > 0 && (
                                <table className="table mt-4">
                                  <thead className="">
                                    <tr>
                                      <th>Item name</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {inventoryList?.items?.map((item, i) => (
                                      <tr key={i}>
                                        <td>
                                          {/* <Link to={'/item/' + item.id} key={item.id}> */}
                                          {item.name}
                                          {/* </Link> */}
                                        </td>
                                        <td className="d-flex justify-content-end">
                                          <i
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              this.props.deleteItemFromList(
                                                listId,
                                                i,
                                              )
                                            }
                                            className="material-icons">
                                            delete
                                          </i>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">Inventory details</div>
                      </div>
                      <div className="card-body pb-0">
                        <div className="name font-weight-bold">Name</div>
                        <div className="description pb-3">
                          {this.state.isEditState ? (
                            <input
                              className="form-control"
                              value={inventoryList.name}
                              style={{ maxWidth: 620 }}
                              onChange={({ target }) => {
                                this.setState({
                                  inventoryList: {
                                    ...inventoryList,
                                    name: target.value,
                                  },
                                });
                              }}
                            />
                          ) : (
                            <span>{inventoryList.name}</span>
                          )}
                        </div>
                        <div className="name font-weight-bold">Description</div>
                        <div className="description">
                          {this.state.isEditState ? (
                            <textarea
                              className="form-control"
                              onChange={({ target }) => {
                                this.setState({
                                  inventoryList: {
                                    ...inventoryList,
                                    description: target.value,
                                  },
                                });
                              }}
                              rows="5"
                              value={inventoryList.description}
                              style={{ maxWidth: 620 }}
                            />
                          ) : (
                            <span>{inventoryList.description}</span>
                          )}
                        </div>
                      </div>
                      <div
                        className="d-sm-block"
                        style={{ width: '100%', flexDirection: 'row' }}>
                        <div className="col-md-12 d-flex justify-content-between">
                          <button
                            className="blue-btn d-flex align-items-center"
                            onClick={() =>
                              this.setState({
                                isEditState: !this.state.isEditState,
                              })
                            }>
                            Edit
                          </button>
                          <div 
                        className="d-none d-sm-block">
                          <button
                            className="outline-icon-btn d-flex align-items-center"
                            style={{ color: '#00a6b4' }}
                            data-toggle="modal"
                            data-target="#deleteInventoryModal">
                            <div className="delete-hover-icon mr-1"></div>
                            Delete Inventory list
                          </button>
                          </div>
                        </div>
                        <div 
                           className="d-md-down-none d-block d-sm-none justify-content-end" style={{marginTop: 20}}>
                          <button
                            className="outline-icon-btn d-flex align-items-center justify-content-end"
                            style={{ color: '#00a6b4' }}
                            data-toggle="modal"
                            data-target="#deleteInventoryModal">
                            <div className="delete-hover-icon mr-1"></div>
                            Delete Inventory list
                          </button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-md-down-none d-block d-sm-none fix-close-btn">
              <button
                type="button"
                className="outline-blue-btn long-btn mr-3"
                aria-haspopup="true"
                onClick={this.goBack}
                aria-expanded="false">
                Cancel
              </button>
              <button
                  onClick={()=> this.handleSave.call(this, listId)}
                  type="button"
                  className="blue-btn long-btn"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Save
                </button>
            </div>

            <div className="modal fade" id="deleteInventoryModal" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Delete Inventory list</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal">
                      &times;
                    </button>
                  </div>
                  <div className="modal-body pt-0">
                    <p>
                      Are you sure you want to delete
                      <strong>“{inventoryList.name}”</strong>
                      inventory list?
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      ref={this.button}
                      type="button"
                      className="primary-contained-btn long-btn"
                      data-dismiss="modal">
                      Cancel
                    </button>
                    <NavLink to="/inventoryLists">
                      <button
                        type="button"
                        onClick={() => {
                          this.button.current.click();
                          this.deleteInventoryList(listId);
                        }}
                        className="blue-btn long-btn">
                        Delete
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            {/* Add New Item Dialog */}
            <div
              id="addNewItemInventorymodal"
              className="modal fade"
              role="dialog">
              <div className="modal-dialog add-new-item-modal">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Add new item</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal">
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="d-md-down-none d-block d-sm-none">
                      <ul className="new-item-filter">
                        <li
                          onClick={() => this.setState({ itemsFrom: '0' })}
                          className={
                            itemsFrom === '0' ? 'active' : ''
                          }>
                          Search
                        </li>
                        <li
                          onClick={() => this.setState({ itemsFrom: '1' })}
                          className={
                            itemsFrom === '1' ? 'active' : ''
                          }>
                          Category
                        </li>
                        <li
                          onClick={() => this.setState({ itemsFrom: '2' })}
                          className={
                            itemsFrom === '2' ? 'active' : ''
                          }>
                          Display All
                        </li>
                      </ul>
                      {this.state.itemsFrom === '1' && (
                        <div className="category-opts">
                          <div className="name font-weight-bold">
                            Choose category
                          </div>
                          <select
                            className="form-control"
                            onChange={({ target }) =>
                              this.setState({
                                category: target.value,
                                show: false,
                              })
                            }>
                            {categories?.map((name, i) => {
                              if (!category && !name) return false;
                              return (
                                <option key={i} value={name}>
                                  {!name
                                    ? 'All'
                                    : name.charAt(0).toUpperCase() +
                                      name.slice(1)}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                      {this.state.itemsFrom === '0' && (
                        <div className="search-box">
                          <div className="d-flex">
                            <input
                              className="form-control height-inherit"
                              placeholder="Type name of an item"
                              value={this.state.search}
                              onChange={this.searchTextChange.bind(this)}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="d-none d-sm-block">
                      <div className="d-flex mb-4 two-select-group mt-2 pb-1 flex-wrap ">
                        <div>
                          <div className="name font-weight-bold">
                            Add items from
                          </div>
                          <div className="d-flex">
                            <select
                              className="form-control category-select mr-2"
                              onChange={this.handleChange.bind(this)}>
                              <option value="0">Search</option>
                              <option value="1">Category</option>
                              <option value="2">List of all items</option>
                            </select>
                            {this.state.itemsFrom === '0' && (
                              <div className="search-box">
                                <div className="d-flex">
                                  <input
                                    className="form-control height-inherit"
                                    placeholder="Type name of an item"
                                    value={this.state.searchText}
                                    onChange={this.searchTextChange.bind(this)}
                                  />
                                  {/* <button className="blue-btn" style={{width: 200, marginLeft: 10 }}>Add item</button> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {this.state.itemsFrom === '1' && (
                          <div className="category-opts">
                            <div className="name font-weight-bold">
                              Choose category
                            </div>
                            <select
                              className="form-control"
                              onChange={({ target }) =>
                                this.setState({
                                  category: target.value,
                                  show: false,
                                })
                              }>
                              {categories?.map((name, i) => {
                                if (!category && !name) return false;
                                return (
                                  <option key={i} value={name}>
                                    {!name
                                      ? 'All'
                                      : name.charAt(0).toUpperCase() +
                                        name.slice(1)}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                        <div className="category-opts"></div>
                      </div>
                    </div>
                    <ul className="mobile-view d-md-down-none d-block d-sm-none">
                      {inventoryItems
                        ?.filter((item) =>
                           itemsFrom !== '1' ? true : category ? item?.category === category : true,
                        )
                        ?.filter((item) =>
                        itemsFrom !== '0' ? true :  
                        search.length > 0
                            ? item?.name
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            : true,
                        )
                        ?.map((item, i) => (
                          <li key={i}>
                            <div className="justify-content-between align-items-center">
                              <div className="item">
                                <p className="title">{item.name}</p>
                                <div className="content justify-content-between" style={{display: 'flex'}}>
                                  <span>
                                    <input
                                      type="text"
                                      className="short-input"
                                      defaultValue={item.par}
                                      readOnly={true}
                                    />
                                  </span>
                                  <span className="justify-content-end">
                                    <button
                                      className="blue-btn"
                                      style={{ minWidth: 60 }}>
                                      Add
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      ;
                    </ul>
                   <div className="d-none d-sm-block" style={{height: 300, overflow: 'scroll'}}>
                    <table className="table" >
                      {/* <table className="responsive-tabe highlight centered itemtable"> */}
                      <thead>
                        <tr>
                          <th>Item name</th>
                          <th className="d-flex justify-content-end">
                            <span className="mr-4">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {inventoryItems
                        ?.filter((item) =>
                           itemsFrom !== '1' ? true : category ? item?.category === category : true,
                        )
                        ?.filter((item) =>
                        itemsFrom !== '0' ? true :  
                        search.length > 0
                            ? item?.name
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            : true,
                        )
                          ?.map((item, i) => (
                            <tr key={i}>
                              <td>{item.name}</td>
                              <td className="d-flex justify-content-end">
                                <button
                                  onClick={() => {
                                    this.props.addItemToList(listId, item);
                                  }}
                                  className="blue-btn"
                                  style={{ minWidth: 60 }}>
                                  Add
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  </div>
                  <div className="modal-footer">
                    {/* <a className="modal-close waves-effect waves-green btn-flat">Close</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container center">
          <p>Loading item...</p>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateInventoryList: (listId, item) => dispatch(updateInventoryList(listId, item)),
    addItemToList: (listId, itemId) => dispatch(addItemToList(listId, itemId)),
    deleteItemFromList: (listId, itemId) =>
      dispatch(deleteItemFromList(listId, itemId)),
    deleteInventoryList: (listId) => dispatch(deleteInventoryList(listId)),
  };
};
const mapStateToProps = (state, ownProps) => {
  //console.log(state);

  // Todo: We should get just the specific list directly from firestore instead of all of the inventory lists and then filtering it out here
  console.log("state: ", state);
  const id = ownProps.match.params.id;
  const inventoryLists = state.firestore.data.inventoryLists;
  const inventoryList = inventoryLists ? inventoryLists[id] : null;

  console.log("inventoryList: ", inventoryList);

  const inventoryItems = state.firestore.ordered.inventoryItems;
  console.log("inventoryItems: ", inventoryItems);

  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    inventoryList,
    inventoryItems,
    categories: state.item.categories,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    //    console.log('defaultCafe: ', props.profile.defaultCafe);
    //    console.log('ownProps: ', ownProps.match.params.id);

    if (!props.profile.defaultCafeId) {
      return [];
    }

    return [
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [
          {
            collection: "inventoryList",
          },
        ],
        storeAs: "inventoryLists",
      },
      {
        collection: "cafes",
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: "inventoryItems" }],
        storeAs: "inventoryItems",
      },
    ];
  })
)(InventoryListDetails);