import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { addItemToList } from '../../store/actions/shoppingActions';
import moment from 'moment';

class HistoryItem extends React.Component {

  render() {
    const { historyItems } = this.props;
    return (
      <div id="shoppingHistory" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h4 className="text-title">Shopping trip 1</h4> */}
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">

              {/* Begin Mobile View */}
              <div className="card-body pb-0 d-md-down-none d-block d-sm-none p-0">
                <ul className="mobile-view">
                  {historyItems.map((item, key) => {
                    return (
                      <li key={key}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="item w-100">
                            <p className="content d-flex justify-content-between">
                              <span style={{fontWeight: 'bold'}}>{item.name}</span>
                              <span>{item.category}</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Begin Desktop View */}
              <div className="d-none d-sm-block">
                <table className="table shopping-history">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyItems.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{item.name}</td>
                          <td>{item.category}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToList: (list) => dispatch(addItemToList(list)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    items: state.firestore.data.items,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    if (!props.profile.defaultCafeId) {
      return [];
    }
    return [
      {
        collection: 'cafes',
        doc: props.profile.defaultCafeId,
        subcollections: [{ collection: 'inventoryItems' }],
        storeAs: 'items',
      },
    ];
  }),
)(HistoryItem);
