export const createProduct = (product) => {
  return (dispatch, getState, { getFirebase }) => {
    // make async call to database
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;
    const fullPro = {
      ...product,
      createdBy: authorId,
      dateCreated: new Date(),
    };
    console.log(fullPro)
    firestore
      .collection("products")
      .add(fullPro)
      .then(() => {
        dispatch({ type: "CREATE_PRODUCT_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_PRODUCT_ERROR" }, err);
      });
  };
};

export const updateProduct = (productId, product) => {
  console.log('productId: ', productId);
  console.log('product: ', product);

  return (dispatch, getState, { getFirebase }) => {
    // make async call to database
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid;

    firestore
      .collection("products")
      .doc(productId)
      .update({
        ...product,
        updatedBy: authorId,
        dateUpdated: new Date(),
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_PRODUCT_ERROR" }, err);
      });
  };
};

export const getProductMinPrice = (productId) => {
  console.log('productId: ', productId);
  return (dispatch, getState, { getFirebase }) => {
    // make async call to database
    const firestore = getFirebase().firestore();
        firestore
          .collection('products')
          .doc(productId)
          .get()
          .then((doc2) => {
            dispatch({
              type: 'UPDATE_MIN_PRICE',
              productUPC: {
                productId: productId,
                upc: doc2.data().upc,
                description: doc2.data().description,
              },
            });
          });
  };
};


export default createProduct;
